import { GridColDef } from "@mui/x-data-grid";

export var GridFieldsEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Order",
    width: 100,
  },
  {
    field: "customerId",
    headerName: "Customer",
    width: 200,
  },

  {
    field: "numberCards",
    headerName: "Number Cards",
    width: 200,
  },
  {
    field: "orderDT",
    headerName: "Order Data",
    width: 200,
  },
  {
    field: "vendorId",
    headerName: "Vendor",
    width: 200,
  },
  {
    field: "nominalCardPrice",
    headerName: "Nominal Card Price",
    width: 200,
  },
  {
    field: "cardPrice",
    headerName: "Card Price",
    width: 200,
  },
  {
    field: "totalAmount",
    headerName: "Order Amount",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "Currency ",
    width: 200,
  },
  {
    field: "cardCategoryId",
    headerName: "Category",
    width: 200,
  },
  {
    field: "cardSubCategoryId",
    headerName: "Sub Category",
    width: 200,
  },
  {
    field: "tenantId",
    headerName: "Tenant",
    width: 200,
  },
];

export var GridFieldsAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "طلب",
    width: 100,
  },
  {
    field: "customerId",
    headerName: "عميل",
    width: 200,
  },

  {
    field: "numberCards",
    headerName: "بطاقات الأرقام",
    width: 200,
  },
  {
    field: "orderDT",
    headerName: "بيانات الطلبيات",
    width: 200,
  },
  {
    field: "vendorId",
    headerName: "بائع",
    width: 200,
  },
  {
    field: "nominalCardPrice",
    headerName: "سعر البطاقة الاسمية",
    width: 200,
  },
  {
    field: "cardPrice",
    headerName: "سعر البطاقة",
    width: 200,
  },
  {
    field: "totalAmount",
    headerName: "كمية الطلب",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "عملة",
    width: 200,
  },
  {
    field: "cardCategoryId",
    headerName: "فئة",
    width: 200,
  },
  {
    field: "cardSubCategoryId",
    headerName: "تصنيف فرعي",
    width: 200,
  },
  {
    field: "tenantId",
    headerName: "مستأجر",
    width: 200,
  },
];
