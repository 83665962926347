import React, { FC } from "react";
import { Container, Row } from "react-bootstrap";
import Card from "../commonComponents/cards/navigationCard/Card";
import { useTranslation } from "react-i18next";

const BusinessProcesses: FC = () => {
  const { t } = useTranslation();

  const CardsArray = [
    {
      title: t("order_workbench"),
      text: "",
      link: "/OrderWorkbench",
      roles: ["SuperAdmin", "Admin"],
      systemConfig: true,
    },
    {
      title: t("directcharge_order_workbench"),
      text: "",
      link: "/DirectChargeOrderWorkbench",
      roles: ["SuperAdmin", "Admin"],
      systemConfig: true,
    },
  ];

  return (
    <Container>
      <div className="overviewCardContainer">
        <Row>
          {CardsArray.map((record, i) => (
            <Card
              title={record.title}
              cardBody={record.text}
              link={record.link}
              key={i}
            />
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default BusinessProcesses;
