import { createContext } from "react";
import { DirectChargeOrderModel } from "../../../../models/order/DirectChargeOrder";

export interface IDirectChargeOrderContext {
  operation?: string;
  orderId?: string;
  orderData?: DirectChargeOrderModel | undefined;
  setOrderData?: React.Dispatch<
    React.SetStateAction<DirectChargeOrderModel | undefined>
  >;
  changeHandler?: (e: any) => void;
  dropDowns?: {};
}

export const DirectChargeOrderContext = createContext<IDirectChargeOrderContext | null>(
  null
);
