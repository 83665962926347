type TypeTableSettings = {
  baseURL: string;
};

var Currency: TypeTableSettings = { baseURL: "InternalConfig" };
var UserType: TypeTableSettings = { baseURL: "InternalConfig" };
var Tenant: TypeTableSettings = { baseURL: "InternalConfig" };
var FIDocType: TypeTableSettings = { baseURL: "InternalConfig" };
var VendorType: TypeTableSettings = { baseURL: "InternalConfig" };
var Message: TypeTableSettings = { baseURL: "InternalConfig" };
var Category: TypeTableSettings = { baseURL: "Config" };
var SubCategory: TypeTableSettings = { baseURL: "Config" };
var Vendor: TypeTableSettings = { baseURL: "MasterData" };
var CustomerPrice: TypeTableSettings = { baseURL: "MasterData" };
var User: TypeTableSettings = { baseURL: "User" };
var Role: TypeTableSettings = { baseURL: "InternalConfig" };
var CustomerCatPrice: TypeTableSettings = { baseURL: "Price" };
var CustomerSubCatPrice: TypeTableSettings = { baseURL: "Price" };

export var TableSetting = {
  Currency,
  UserType,
  Tenant,
  FIDocType,
  VendorType,
  Message,
  Category,
  SubCategory,
  Vendor,
  CustomerPrice,
  User,
  Role,
  CustomerCatPrice,
  CustomerSubCatPrice
};
