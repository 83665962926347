import { useState } from "react";
import Axios from "axios";
import { Method } from "axios";
import toastr from "toastr";
import TypeBackendMessags from "./../types/BackendMessages";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../commonComponents/loaders/newLoader/LoadingProvder";
let allBackendMessages: Array<TypeBackendMessags>;

interface IonSuccess {
  data?: any;
}

export interface IFetchReturn {
  fetchData: FetchDataType;
  outData: any;
  setOutData: React.Dispatch<React.SetStateAction<{}>>;
  error: string[];
  setError: React.Dispatch<React.SetStateAction<Array<string>>>;
  success: string[];
  setSuccess: React.Dispatch<React.SetStateAction<Array<string>>>;
  loading: boolean;
}

type FetchDataType = (
  callingData?: any,
  onSuccess?: (response: IonSuccess) => void,
  newURL?: string,
  onError?: (response: IonSuccess) => void
) => Promise<void>;

export const useFetch = (
  url: string,
  method: Method | string,
  noToasterMessages?: boolean
): IFetchReturn => {
  const [outData, setOutData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Array<string>>([]);
  const [success, setSuccess] = useState<Array<string>>([]);
  const { startLoading, stopLoading } = useLoading();

  const fetchData: FetchDataType = async (
    callingData,
    onSuccess,
    newURL,
    onError
  ) => {
    let usedUrl: string;
    if (newURL) {
      usedUrl = newURL;
    } else {
      usedUrl = url;
    }
    try {
      //setLoading(true);

      startLoading();

      const { data: response } = await Axios({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("rc_token"),
        },
        url: usedUrl,
        method: method,
        data: callingData,
      });
      if (onSuccess) {
        onSuccess(response);
      }
      if (response.data != null) {
        setOutData(response.data);
      }

      allBackendMessages = response?.messages || [];

      var foundError = allBackendMessages.some((x) => x.messageType === "E");
      var foundSuccess = allBackendMessages.some((x) => x.messageType === "S");

      if (foundError) {
        for (const message of response.messages) {
          if (message.messageType === "E") {
            !noToasterMessages && toastr.error(message.messageText);
            setError((current) => {
              return [...current, message.messageText];
            });
          }
        }
      }
      if (foundSuccess) {
        for (const message of response.messages) {
          if (message.messageType === "S") {
            !noToasterMessages && toastr.success(message.messageText);
            setSuccess((current) => {
              return [...current, message.messageText];
            });
          }
        }
      }

      if (foundError) {
        if (onError) {
          onError(response);
        }
      }
    } catch (e: any) {
      // setLoading(false);
      //if backend is down
      if (e.response == null) {
        !noToasterMessages && toastr.error("No Response from Backend" + url);
        setError((current) => {
          return [...current, "No Response from Backend" + url];
        });
        return;
      }

      //if backend is up but execption occured, send message sent in "e"
      let totalMessage = `Error Calling${usedUrl}, ${e.message}`;
      !noToasterMessages && toastr.error(totalMessage);
      setError((current) => {
        return [...current, totalMessage];
      });

      if (e.response.statusText != null) {
        !noToasterMessages && toastr.error(e.response.statusText);
        setError((current) => {
          return [...current, e.response.statusText];
        });
      }
      if (onError) {
        onError(e.response);
      }
    } finally {
      // setLoading(false);
      stopLoading();
    }
  };

  return {
    fetchData,
    outData,
    setOutData,
    error,
    setError,
    success,
    setSuccess,
    loading,
  };
};
