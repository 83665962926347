import { createContext } from "react";
import { OrderModel } from "../../../../models/order/order";
import { TypeAllDropDownTables } from "../../../../types/BackendDropDown";
export interface IOrderContext {
  operation?: string;
  orderId?: string;
  orderData?: OrderModel | undefined;
  setOrderData?: React.Dispatch<React.SetStateAction<OrderModel | undefined>>;
  changeHandler?: (e: any) => void;
  dropDowns?: TypeAllDropDownTables | any;
}

export const OrderContext = createContext<IOrderContext | null>(null);
