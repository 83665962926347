import React, { FC, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./AppHeader.css";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { newUserData } from "../../redux/features/UserSlice";
import { UseFetch } from "../../Hooks/UseFetchNew";
import { BaseURLs } from "../GlobalFiles/URLs";

const AppHeader: FC = () => {
  const userInfo = useAppSelector((state) => state.userInfo);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const updateUser = UseFetch({
    url: `${BaseURLs?.User}UpdateUser`,
    method: "PUT",
    loading: true,
    noToasterMessages: true,
  });
  console.log("i18n", i18n);

  const changeLanguage = (language: string) => {
    const updatedUserInfo = {
      ...userInfo,
      preferredLanguage: language === "ar" ? "AR" : "EN",
    };
    updateUser.fetchData({
      callingData: {
        ...updatedUserInfo,
      },
      onSuccess: ({ data }) => {
        if (data) {
          dispatch(newUserData(data));
          document.body.dir = language == "ar" ? "rtl" : "ltr";
          i18n.changeLanguage(language);
        }
      },
      onError: (res) => {},
    });
  };

  useEffect(() => {
    userInfo.preferredLanguage === "AR"
      ? i18n.changeLanguage("ar")
      : i18n.changeLanguage("en");
  }, [userInfo]);

  return (
    <Navbar className="mainNavBar mb-5" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/Home">
          <div className="header-content">
            <span className="welcome-text">
              {t("rayan_cards_welcome", { name: userInfo.firstName })}
            </span>
            {userInfo.loggedIn && i18n.language !== "ar" && (
              <button
                className="language-btn"
                onClick={() => changeLanguage("ar")}
              >
                AR
              </button>
            )}
            {userInfo.loggedIn && i18n.language !== "en" && (
              <button
                className="language-btn"
                onClick={() => changeLanguage("en")}
              >
                EN
              </button>
            )}
          </div>
        </Navbar.Brand>
        {userInfo.loggedIn && (
          <Nav>
            <Nav.Link as={Link} to="/Home">
              {t("home")}
            </Nav.Link>
            <Nav.Link as={Link} to="/Orderworkbench">
              {t("orders")}
            </Nav.Link>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
};

export default AppHeader;
