// import { MDBBtn } from "mdb-react-ui-kit";
import { Button } from "@mui/material";
import React from "react";
import { ClipLoader } from "react-spinners";

interface IButton {
  onClick: () => void;
  isLoading?: boolean;
  title: string;
}

function ButtonMUISpinnner({ onClick, isLoading, title }: IButton) {
  return (
    <Button
      disabled={isLoading}
      onClick={onClick}
      className="w-100 my-4"
      size="large"
      type="button"
      variant="contained"
    >
      {isLoading ? (
        <ClipLoader
          size={22}
          color={"#fff"}
          loading={true}
          speedMultiplier={1.5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        title
      )}
    </Button>
  );
}

export default ButtonMUISpinnner;
