import React, { FC } from "react";
import { Container, Row } from "react-bootstrap";
import Card from "../commonComponents/cards/navigationCard/Card";
import { useTranslation } from "react-i18next";

const Reports: FC = () => {
  const { t } = useTranslation();

  const CardsArray = [
    {
      title: t("available_cards"),
      text: "",
      link: "/AvailableCards",
      roles: ["SuperAdmin", "Admin"],
      systemConfig: true,
    },
    {
      title: t("customer_financials"),
      text: "",
      link: "/CustomerFinancials",
      roles: ["SuperAdmin", "Admin"],
      systemConfig: true,
    },
  ];

  return (
    <Container>
      <div className="overviewCardContainer">
        <Row>
          {CardsArray.map((record, i) => (
            <Card
              title={record.title}
              cardBody={record.text}
              link={record.link}
              key={i}
            />
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default Reports;
