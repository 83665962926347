import React, { FC, useState } from "react";
import { loginArray } from "./Fields";
import { Reducer } from "../../commonComponents/rendering/originalReducer/Reducer";
import { useNavigate, useLocation } from "react-router-dom";
import { BaseURLs } from "./../../commonComponents/GlobalFiles/URLs";
import LoginCard from "../../commonComponents/cards/LoginCard/LoginCard";
import { useTranslation } from "react-i18next";
import ButtonMUISpinnner from "../../commonStyles/buttons/ButtonMUISpinnner";
import { newUserData } from "../../redux/features/UserSlice";
import { useAppDispatch } from "../../redux/hooks";
import { UseFetch } from "../../Hooks/UseFetchNew";
import { IUseMessageReturn, useMessageI18n } from "../../Hooks/useMessageI18n";

const Login: FC = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const updateUser = UseFetch({
    url: `${BaseURLs?.User}UpdateUser`,
    method: "PUT",
    loading: true,
    noToasterMessages: true,
  });

  const updateUserFunc = (updatedUserInfo: any) => {
    updateUser.fetchData({
      callingData: {
        ...updatedUserInfo,
      },
      onSuccess: ({ data }) => {
        if (data) {
          dispatch(newUserData(data));
        }
      },
      onError: (res) => {},
    });
  };
  const useMessages: IUseMessageReturn = useMessageI18n();
  type LoginData = {
    userName: string;
    password: string;
    language: string;
  };
  const [loginFields, setLoginFields] = useState<LoginData>({
    userName: "",
    password: "",
    language: "EN",
  });
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();

  const authenticate = UseFetch({
    url: BaseURLs.Authenticate,
    method: "POST",
    loading: true,
    noToasterMessages: true,
  });
  return (
    <div className="container">
      <h3 className="text-center my-3">
        {t(`pages.login.title`, { projectName: "Rayan Cards" }).toString()}
      </h3>

      <LoginCard
        onSubmit={() => {}}
        cardBody={
          <div>
            {loginArray({ dataSource: loginFields }).map((field, index) => {
              return (
                <Reducer
                  key={index.toString()}
                  {...field}
                  onChange={(e: any) => {
                    const { name, value } = e.target;
                    setLoginFields((prev) => ({
                      ...prev,
                      [name]: value,
                    }));
                    //                  handleChange(e);
                    //pass the language to I18n lib
                    if (field.id == "language") {
                      document.body.dir =
                        e?.target?.value == "AR" ? "rtl" : "ltr";
                      e?.target?.value == "AR"
                        ? changeLanguage("ar")
                        : changeLanguage("en");
                    }
                  }}
                />
              );
            })}
            <ButtonMUISpinnner
              title={t("sign_in")}
              isLoading={false}
              onClick={() => {
                authenticate.fetchData({
                  callingData: {
                    ...loginFields,
                  },
                  onSuccess: ({ data }) => {
                    if (data) {
                      const userdata = {
                        ...data,
                        preferredLanguage: i18n.language === "ar" ? "AR" : "EN",
                      };
                      localStorage.setItem("rc_token", userdata.token);
                      localStorage.setItem("rc_id", userdata.id);

                      updateUserFunc(userdata);
                      if (location.pathname == "/Login") {
                        navigate(`/Home`);
                      } else {
                        navigate(`/Home`);
                        //                        navigate(location.pathname);
                      }
                    }
                  },
                  onError: (res) => {},
                });
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export default Login;
