export type MessageFileType = Array<{id:string, message:string}>

type FetchDataType = () => MessageFileType;

export const Arabic = ():MessageFileType => {

    return [ { "id": "1", "message": "أدخل رقم الطلب" },  
             { "id": "2", "message": "لم يتم تكوين حقول عرض التفاصيل للجدول رقم# 1" },
             { "id": "3", "message": "لم يتم تكوين حقول عرض الشبكة للجدول رقم# 1 " },
             { "id": "4", "message": "يجب تحديد صف قبل محاولة المضي قدما" }  ,  
             { "id": "5", "message": "لا يمكن الحفظ ، البيانات غير كاملة" },            
            ]
}