import React, { FC } from "react";
import { IFields } from "./IFields";
import { DateTimePicker } from "react-rainbow-components";
import moment from "moment";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { TextField, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import CheckboxMui from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import "./Reducer.scss";

export const Reducer: FC<IFields> = (props) => {
  switch (props.tag) {
    case "input":
      return <TextInput {...props} />;
    case "inputMui":
      return <TextInputMui {...props} />;
    case "select":
      return <SelectInput {...props} />;
    case "selectMui":
      return <SelectInputMui {...props} />;
    case "DateTime":
      return <DateTimeInput {...props} />;
    case "DateTimeN":
      return <DateTimeInputN {...props} />;
    case "ImageUpload":
      return <ImageUpload {...props} />;
    case "Checkbox":
      return <Checkbox {...props} />;
    case "CheckboxMui":
      return <CheckboxInputMui {...props} />;
    default:
      return <TextInput {...props} />;
  }
};

const TextInputMui: FC<IFields> = (props) => {
  return (
    <>
      <TextField
        size="medium"
        id={props?.id}
        name={props?.name}
        // color of floating lable and outline
        //        color="success"
        //        defaultValue=""
        value={props?.dataSource[props.id] || ""}
        disabled={props?.disabled}
        label={props?.label}
        required={props?.required}
        onChange={props?.onChange}
        error={!!props.errorMessage}
        className="w-100 mb-4"
        variant="outlined"
      />
      {props.errorMessage && (
        <p style={{ marginTop: -15, marginBottom: 15, color: "red" }}>
          {props.errorMessage}
        </p>
      )}
    </>
  );
};
const TextInput: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  length,
  itemKey,
  required,
}) => {
  function getValue() {
    if (dataSource && dataSource[id]) {
      return dataSource[id].toString();
    }
    return "";
  }

  function getBorderColor() {
    if (required) {
      let fieldValue = getValue();

      if (fieldValue) {
        return "green";
      } else {
        return "red";
      }
    }
  }
  function getBorderWidth(): string {
    if (required) {
      let fieldValue = getValue();
      if (fieldValue) {
        return "2px";
      } else {
        return "4px";
      }
    }
    return "";
  }

  return (
    <div className="mt-2">
      {!noLable ? (
        <label
          className="form-label"
          htmlFor={id}
          // style={{
          //   fontWeight: 600,
          // }}
        >
          {label}:
        </label>
      ) : null}

      <input
        onFocus={(e) => {
          e.stopPropagation();
        }}
        className="form-control"
        value={dataSource[id]?.toString() || ""}
        onChange={onChange}
        data-type={type}
        type={type}
        id={id}
        data-item={dataSource["itemNumber"] || dataSource[itemKey as string]}
        name={name}
        disabled={disabled}
        maxLength={length}
        style={{
          borderRadius: 8,
          padding: 8,
          height: 50,
          fontSize: 20,
          borderColor: getBorderColor(),
          borderWidth: getBorderWidth(),
        }}
      />
    </div>
  );
};

const SelectInput: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  length,
  itemKey,
  required,
  options,
}) => {
  function getValue() {
    if (dataSource && dataSource[id]) {
      return dataSource[id].toString();
    }
    return "";
  }

  function getBorderColor() {
    if (required) {
      let fieldValue = getValue();

      if (fieldValue) {
        return "green";
      } else {
        return "red";
      }
    }
  }
  function getBorderWidth(): string {
    if (required) {
      let fieldValue = getValue();
      if (fieldValue) {
        return "2px";
      } else {
        return "4px";
      }
    }
    return "";
  }

  return (
    <div className="mt-2">
      {!noLable ? (
        <label
          className="form-label"
          htmlFor={id}
          // style={{
          //   fontWeight: 600,
          // }}
        >
          {label}:
        </label>
      ) : null}

      <select
        className="form-control"
        onChange={onChange}
        value={getValue()}
        //        type={type}
        //        data-item={dataSource?.["itemNumber"] || dataSource?.[itemKey]}
        data-type={type}
        disabled={disabled}
        name={name}
        style={{
          borderRadius: 8,
          height: 50,
        }}
      >
        <option value="select">--- SELECT ---</option>
        {options?.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};
const SelectInputMuiObselet: FC<IFields> = (props) => {
  const { options } = props;
  return (
    <>
      <TextField
        id={props?.id}
        name={props?.name}
        value={props?.dataSource[props.id]}
        disabled={props?.disabled}
        label={props.label}
        required={props?.required}
        onChange={props?.onChange}
        select={true}
        // error={true}
        className="w-100 mb-4"
      >
        {options?.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {props.errorMessage && (
        <p style={{ marginTop: -15, marginBottom: 15, color: "red" }}>
          {props.errorMessage}
        </p>
      )}
    </>
  );
};
const DateTimeInput: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  itemKey,
}) => {
  const inputStyles = {
    maxWidth: 320,
  };

  function getValue() {
    if (dataSource && dataSource[id]) {
      return dataSource[id];
    }

    return "2022, 1, 1";
  }

  return (
    <div className="mb-3 dateInput">
      {noLable !== true ? <label htmlFor={id}>{label}:</label> : null}

      <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
        <DateTimePicker
          id={id}
          value={getValue()}
          minDate={new Date(2022, 1, 1)}
          maxDate={new Date(2090, 1, 1)}
          disabled={disabled}
          locale="en-US"
          //          style={inputStyles}
          onChange={(v: Date) => {
            //the formate produced by picker ie v is cuase the backend to save it (convert to) UTC time, by doing this
            //manipulation the backend will save the local time/date without converting it to UTC
            let date = moment(v).format("YYYY-MM-DD");
            let time = moment(v).format("hh:mm:ss");
            let dt = `${date}T${time}`;

            onChange({
              target: {
                value: v,
                name,
                type: "datetime",
                //                item: dataSource[itemKey],
              },
            });
          }}
          name={name}
        />
      </div>
    </div>
  );
};
const DateTimeInputN: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  itemKey,
  fieldValidations,
  highlightIssues,
  length,
}) => {
  const inputStyles = {
    maxWidth: 320,
  };

  function getValue() {
    if (dataSource && dataSource[id]) {
      return dataSource[id];
    }

    return "2022, 1, 1";
  }

  function getLable() {
    let fieldState = fieldValidations?.current.find(
      (x) => x.fieldId === id && x.validationType === "Required"
    );

    if (fieldState) {
      return `${label}*`;
    } else {
      return label;
    }
  }

  function getRequired() {
    if (!highlightIssues) {
      return false;
    }

    let fieldState = fieldValidations?.current.find(
      (x) =>
        x.fieldId === id &&
        x.validationType === "Required" &&
        x.validationResult === false
    );
    //The required flag is set when the validation fails
    if (fieldState?.validationResult === false) {
      return true;
    } else {
      return false;
    }
  }

  function handleDateTimeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedDateTime = e.target.value;

    // Convert selected date and time to UTC
    const utcDateTime = moment(selectedDateTime).utc().format();

    onChange({
      target: {
        value: utcDateTime,
        name,
        type: "datetime",
      },
    });
  }

  function getValueDateTime() {
    //The time zone coming from the state is always UTC then convert to
    //to the user timezone or local timezone for display purposes
    const { DateTimeFormat } = window.Intl;
    const localTimeZone = new DateTimeFormat().resolvedOptions().timeZone;
    const timeZone = localTimeZone;
    if (dataSource[id]) {
      let uTCDT = utcToZonedTime(new Date(dataSource[id]), timeZone);
      if (!isNaN(uTCDT.getTime())) {
        return format(uTCDT, "yyyy-MM-dd'T'HH:mm");
      }
    }
  }
  return (
    <div className="dateInputDiv">
      <p className="label">{getLable()}</p>
      <input
        className="dateInput"
        placeholder={`Select ${label} Here`}
        onFocus={(e) => {
          e.stopPropagation();
        }}
        value={getValueDateTime() ?? ""}
        onChange={handleDateTimeChange}
        type="datetime-local"
        id={id}
        data-item={itemKey}
        name={id}
        disabled={disabled}
        maxLength={length}
        required={getRequired()}
      />
    </div>
  );
  // return (
  //   <div className="dateInputDiv">
  //     <p className="label">{getLable()}</p>
  //     <input
  //       className="dateInput"
  //       placeholder={`Select ${label} Here`}
  //       onFocus={(e) => {
  //         e.stopPropagation();
  //       }}
  //       value={getValueDateTime() ?? ""}
  //       onChange={onChange}
  //       type="datetime-local"
  //       id={id}
  //       data-item={itemKey}
  //       name={id}
  //       disabled={disabled}
  //       maxLength={length}
  //       required={getRequired()}
  //     />
  //   </div>
  // );
};
const ImageUpload: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  itemKey,
}) => {
  const { t } = useTranslation();
  function getValue() {
    if (dataSource && dataSource[id]) {
      return dataSource[id];
    }
    return "";
  }

  const onChangeImage = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    let imageData: { target: {} } = { target: {} };
    imageData.target = { name: id, value: imageList };
    onChange(imageData);
  };

  return (
    <ImageUploading
      multiple={false}
      value={getValue()}
      onChange={onChangeImage}
      maxNumber={1}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div>
          <button
            style={isDragging ? { color: "red" } : undefined}
            onClick={onImageUpload}
            {...dragProps}
          >
            {t("click_or_drop_here")}
          </button>
          &nbsp;
          <button onClick={onImageRemoveAll}>{t("remove_all_images")}</button>
          {imageList.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image.dataURL} alt="" width="100" />
              <div>
                <button onClick={() => onImageUpdate(index)}>Update</button>
                <button onClick={() => onImageRemove(index)}>Remove</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
};
const Checkbox: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  length,
  itemKey,
  required,
}) => {
  return (
    <div className="form-check">
      {!noLable ? (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      ) : null}

      <input
        style={{ height: "20px", width: "20px" }}
        className="form-check-input"
        checked={
          dataSource?.[id] === "true" ||
          dataSource?.[id] === true ||
          dataSource?.[id] === "True"
        }
        value={dataSource?.[id]}
        onChange={onChange}
        data-type={type}
        type={type}
        data-item={dataSource?.["itemNumber"]}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};
const CheckboxInputMui: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  length,
  itemKey,
  required,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <CheckboxMui
            id={id}
            name={name}
            onChange={onChange}
            checked={
              dataSource?.[id] === "true" ||
              dataSource?.[id] === true ||
              dataSource?.[id] === "True"
            }
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
const SelectInputMui: FC<IFields> = (props) => {
  const { options } = props;
  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id={`${props.label}-lable`}>{props.label}</InputLabel>
          <Select
            id={props?.id}
            name={props?.name}
            labelId={`${props.label}-lable`}
            value={props?.dataSource[props.id] || ""}
            label={props.label}
            onChange={props?.onChange}
            required={props?.required}
            error={!!props.errorMessage}
            className="mb-4"
          >
            {options?.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {props.errorMessage && (
            <p style={{ marginTop: -15, marginBottom: 15, color: "red" }}>
              {props.errorMessage}
            </p>
          )}
        </FormControl>
      </Box>
    </>
  );
};
