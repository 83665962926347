import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CardContext, ICardContext } from "./Common/CardContext";
import Header from "./Common/Header";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MainData from "./Main/MainData";
import AdminData from "./Admin/AdminData";
import { CardModel } from "../../../models/MasterData/Card";
import { BaseURLs } from "../../../commonComponents/GlobalFiles/URLs";
import { useMessageI18n } from "../../../Hooks/useMessageI18n";
import { useAppSelector } from "../../../redux/hooks";
import { backend_services } from "../../../services/Backend";
import { useSchema } from "../../../Hooks/useSchema";
import OrderData from "./Order/OrderData";
import { TypeFieldValidations } from "../../../types/TypeFieldValidation";
import { useNavigate } from "react-router-dom";
import { UseFetch } from "../../../Hooks/UseFetchNew";
import useNavigateSearch from "./../../../Hooks/useNavigationSearch";
import { useTranslation } from "react-i18next";
const CardMain: FC = () => {
  const { t } = useTranslation();
  const { CardSchema } = useSchema();
  let navigate = useNavigate();
  const [operation, setOperation] = useState<string>("");
  const [cardId, setCardId] = useState<string>("");
  const [cardData, setCardData] = useState<CardModel>();
  const [fieldValidation, setFieldValidation] = useState<
    TypeFieldValidations | undefined
  >();
  const [key, setKey] = useState<any>("main");
  const [searchParams] = useSearchParams();
  const params = useParams();
  const useMessagesI18n = useMessageI18n();
  const userInfo = useAppSelector((state) => state.userInfo);
  const navigateSearch = useNavigateSearch();
  const dropDowns = useAppSelector((state) => state.dropDowns);
  // const getDropDowns = UseFetch({
  //   url: BaseURLs.AllDropDowns,
  //   method: "GET",
  //   loading: true,
  //   noToasterMessages: true,
  // });

  const saveCards = UseFetch({
    url: `${BaseURLs?.Card}SaveCard`,
    method: "POST",
    loading: true,
    noToasterMessages: true,
  });

  const getCard = UseFetch({
    url: `${BaseURLs?.Card}GetCard`,
    method: "GET",
    loading: true,
    noToasterMessages: true,
  });

  useEffect(() => {
    //the use of this useEffect is to extract the search parameters

    let searchParamObject = Object.fromEntries(searchParams);
    //If there is not supplied operation then set it to Create

    if (searchParamObject?.operation) {
      setOperation(searchParamObject.operation);
    } else {
      navigateSearch("/Card", { operation: "Create" });
    }
    if (searchParamObject?.id) {
      setCardId(searchParamObject?.id);
    }
    console.log(cardId);
    //added the navigate as a dependecy becuase it needed when change path is called
    //after partner is created is saved
  }, [navigate]);

  useEffect(() => {
    debugger;
    if (cardId) {
      getCard.fetchData({
        params: { id: cardId },
        onSuccess: ({ data }) => {
          setCardData(data);
          if (operation == "Create") {
            setCardData((prevData) => ({
              ...prevData,
              id: "",
              modifiedBy: "",
              modifiedOn: undefined,
              createdBy: "",
              createdOn: undefined,
            }));

            setCardId("");
          }
        },
        onError: (res) => {},
      });
    }
  }, [cardId]);

  useEffect(() => {
    // getDropDowns.fetchData({
    //   onSuccess: ({ data }) => {},
    //   onError: (res) => {},
    // });

    //Customers can not view customer(s), this an admin only function
    if (userInfo.userTypeId == "Customer") {
      navigate("/Home");
    }
  }, []);

  function changeHandler(e: any) {
    const { name, value } = e.target;
    setCardData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const contextState: ICardContext = {
    operation,
    cardId,
    cardData,
    setCardData,
    changeHandler,
    dropDowns: dropDowns.data,
    fieldValidation,
  };

  // const operationLoading = isCardLoading;

  var allValidationErrors = fieldValidation?.map((x) => x.message) || [];

  return (
    <CardContext.Provider value={contextState}>
      <div className="container-fluid">
        <Header>
          <Button
            className="btn btn-primary mt-1"
            style={{
              height: "3504ev",
              background: "#08bd55",
              borderColor: "#08bd55",
              fontSize: "40px",
            }}
            onClick={async () => {
              if (!cardData) {
                useMessagesI18n.addMessage("5", "E");
                return;
              }
              saveCards.fetchData({
                callingData: {
                  ...cardData,
                },
                onSuccess: ({ data }) => {
                  if (data) {
                    setCardData(data);
                  }
                  if (operation === "Create") {
                    setOperation("Change");
                    setCardId(data.id);
                    navigateSearch("/Card", {
                      id: data.id,
                      operation: "Change",
                    });
                  }
                },
                onError: (res) => {},
              });
            }}
          >
            {t("save")}
          </Button>
        </Header>
        <Tabs
          id="CardTabs"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
          }}
          className="mb-3"
        >
          <Tab eventKey="main" title={t("main")}>
            <MainData />
          </Tab>
          <Tab eventKey="order" title={t("order")}>
            <h1>
              <OrderData />
            </h1>
          </Tab>
          <Tab eventKey="admin" title={t("admin")}>
            <h1>
              <AdminData />
            </h1>
          </Tab>
        </Tabs>
      </div>
    </CardContext.Provider>
  );
};

export default CardMain;
