import React, { FC } from "react";
import { ISimpleGridFields } from "./interfaces";

export interface ISimpleGridHeader {
  Fields: Array<ISimpleGridFields>;
  ActionColumn?: boolean;
}

const HeaderGrid: FC<ISimpleGridHeader> = ({ Fields, ActionColumn }) => {
  return (
    <thead>
      <tr>
        {Fields.map((field, i) => {
          return (
            <th key={i} className="GridColumnHeading">
              {field.label}
            </th>
          );
        })}

        {Fields.length > 0 && ActionColumn ? (
          <th className="GridColumnHeading">Actions</th>
        ) : null}
      </tr>
    </thead>
  );
};

export default HeaderGrid;
