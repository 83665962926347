import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";

export var TenantGridEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Tenant",
    width: 100,
  },
  {
    field: "universalDescriptor",
    headerName: "Universal Descriptor",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "Currency",
    width: 200,
  },
];

export var TenantGridAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "مستأجر",
    width: 100,
  },
  {
    field: "universalDescriptor",
    headerName: "واصف عالمي",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "عملة",
    width: 200,
  },
];
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const TenantDetailsEN: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Id",
      tag: "text",
      placeholder: "Id",
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      label: "Universal Descriptor",
      tag: "text",
      placeholder: "Universal Descriptor",
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "Currency",
      tag: "select",
      placeholder: "Currency",
      options: dropdown?.currencies,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const TenantDetailsAR: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "بطاقة تعريف",
      tag: "text",
      placeholder: "Id",
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      label: "واصف عالمي",
      tag: "text",
      placeholder: "Universal Descriptor",
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "عملة",
      tag: "select",
      placeholder: "Currency",
      options: dropdown?.currencies,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};
