import React, { FC, useState, useContext } from "react";
import { OrderItemEN, OrderItemAR } from "./Fields";
import SimpleGrid from "../../../../commonComponents/grids/simpleGrid/SimpleGrid";
import { OrderContext } from "../Common/OrderContext";
import { useTranslation } from "react-i18next";
const Items: FC = () => {
  const { i18n } = useTranslation();
  let itemFields = i18n.language === "ar" ? OrderItemAR() : OrderItemEN();
  const contextData = useContext(OrderContext);
  let itemData: Array<any>;

  itemData = contextData?.orderData?.items || [];

  function gridClichHandler(data: {}) {}

  return (
    <div className="container-fluid">
      <SimpleGrid
        Fields={itemFields}
        data={itemData}
        ActionColumn={false}
        handleButtonClickGrid={gridClichHandler}
      />
    </div>
  );
};

export default Items;
