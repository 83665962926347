import React, { FC, useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import { useCustomerReport } from "../../Hooks/UseCustomerReport";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { Container } from "react-bootstrap";
import { useMessageI18n } from "../../Hooks/useMessageI18n";
import { useTranslation } from "react-i18next";
const CustomerFinancials: FC = () => {
  const { t } = useTranslation();
  var GridFields: Array<GridColDef> = [
    {
      field: "id",
      headerName: t("customer_id") ?? "",
      width: 300,
    },

    {
      field: "firstName",
      headerName: t("first_name") ?? "",
      width: 300,
    },
    {
      field: "lastName",
      headerName: t("last_name") ?? "",
      width: 300,
    },
    {
      field: "balance",
      headerName: t("balance") ?? "",
      width: 200,
    },
  ];
  let navigate = useNavigate();
  const useMessagesI18n = useMessageI18n();
  const { refetch } = useCustomerReport();
  const userInfo = useAppSelector((state) => state.userInfo);
  const selectionString = useRef<any>();
  const [gridData, setGridData] = useState<any>();

  useEffect(() => {
    //Customer can not display customer(s), this an admin only function
    if (userInfo.userTypeId == "Customer") {
      navigate("/Home");
    }
    RunReport();
  }, []);

  const RunReport = async () => {
    let whereString: {} = {};
    let andWhereString: {} = {};

    whereString = { and: [andWhereString] };

    selectionString.current = whereString;

    let variableString: any = {
      whereString,
    };
    const { data } = await refetch(variableString);
    if (data?.customers.length > 0) {
      const newArray = data?.customers.map((item: any) => {
        const { __typename, ...rest } = item;
        return rest;
      });
      setGridData(newArray);
    } else {
      useMessagesI18n.addMessage("7", "E");
      return;
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          gap: "20px",
        }}
      >
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={RunReport}
        >
          {t("searchCustomer")}
        </Button>
      </div>
      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={gridData || []}
          columns={GridFields}
          pageSize={10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(selection) => {}}
        />
      </Box>
    </Container>
  );
};

export default CustomerFinancials;
