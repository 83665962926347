import {
  gql,
  useQuery,
} from "@apollo/client";
import { IuseReturn } from "./IUseReturn";

// export interface IuseCardReportReturn {
//   execMe: LazyQueryExecFunction<any, any>;
//   data: any;
//   loading: boolean;
//   error: ApolloError | undefined;
// }

// export const useCardReport = (selection: any): IuseCardReportReturn => {
//   const dynamicQuery = gql`
//     query getAll($whereString1: CardFilterInput!) {
//       cards(where: $whereString1) {
//         id
//         cardNumber
//         serialNumber
//         vendorId
//         categoryId
//         subCategoryId
//         customerId
//         status
//         orderId
//         orderDT
//       }
//     }
//   `;

//   const [execMe, { loading, error, data }] = useLazyQuery(dynamicQuery);

//   return { execMe, data, loading, error };
// };

export const useCard = (): IuseReturn => {
  const useCardReport = gql`
  query GetCards($whereString: CardFilterInput!) {
    cards(where: $whereString) {
      id
      cardNumber
      serialNumber
      vendorId
      categoryId
      subCategoryId
      customerId
      status
      orderId
      orderDT
    }
  }
  `;
  const { loading, error, data, refetch } = useQuery(useCardReport, {
    skip: true, // Skip the initial execution
  });

  return { refetch, data, loading, error };
};