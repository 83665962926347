import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { IUser } from "../../models/interfaces/Iuser";
// Define the initial state using that type
const initialState: IUser = {
  loggedIn: false,
};

export const UserSlice = createSlice({
  name: "userData",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    newUserData(state, action) {
      return (state = {
        ...action.payload,
        loggedIn: true,
      });
    },
    LogOut(state) {
      localStorage.removeItem("rc_token");
      localStorage.removeItem("rc_id");
      return (state = initialState);
    },
  },
});

export const { newUserData, LogOut } = UserSlice.actions;

export default UserSlice.reducer;
