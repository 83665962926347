import { useTranslation } from "react-i18next";
import { IFields } from "../../commonComponents/rendering/originalReducer/IFields";
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const ConfigTables: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "table",
      name: "table",
      type: "text",
      label: t("table_name"),
      tag: "select",
      options: [
        { value: "Currency", label: t("currency") },
        { value: "Category", label: t("category") },
        { value: "SubCategory", label: t("sub_category") },
        { value: "Vendor", label: t("vendor") },
        { value: "CustomerCatPrice", label: t("customer_price_cat") },
        {
          value: "CustomerSubCatPrice",
          label: t("customer_price_subcat"),
        },
      ],
    },
  ];
};
