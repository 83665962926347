import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../../../commonComponents/rendering/originalReducer/IFields";

export var CategoryGridEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Category",
    width: 100,
  },
  {
    field: "universalDescriptor",
    headerName: "Universal Descriptor",
    width: 200,
  },
  {
    field: "localizedText",
    headerName: "Local Text",
    width: 200,
  },
  {
    field: "vendorId",
    headerName: "Vendor Id",
    width: 100,
  },
  {
    field: "tenantId",
    headerName: "Tenant Id",
    width: 200,
  },
  {
    field: "imageType",
    headerName: "Image Type",
    width: 200,
  },
  {
    field: "imageData",
    headerName: "Image Data",
    width: 200,
  },
];

export var CategoryGridAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "فئة",
    width: 100,
  },
  {
    field: "universalDescriptor",
    headerName: "واصف عالمي",
    width: 200,
  },
  {
    field: "localizedText",
    headerName: "النص المحلي",
    width: 200,
  },
  {
    field: "vendorId",
    headerName: "رقم المورد",
    width: 100,
  },
  {
    field: "tenantId",
    headerName: "معرف المستأجر",
    width: 200,
  },
  {
    field: "imageType",
    headerName: "نوع الصورة",
    width: 200,
  },
  {
    field: "imageData",
    headerName: "بيانات الصورة",
    width: 200,
  },
];
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const CategoryDetailsEN: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Id",
      tag: "text",
      placeholder: "Id",
      length: 24,
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      label: "Universal Descriptor",
      tag: "text",
      placeholder: "Universal Descriptor",
      length: 50,
    },
    {
      id: "localizedText",
      name: "localizedText",
      type: "text",
      label: "Localized Text",
      tag: "text",
      placeholder: "LocalizedText",
    },
    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: "Vendor Id",
      tag: "select",
      placeholder: "VendorId",
      length: 24,
      options: dropdown?.vendors,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "Tenant Id",
      tag: "select",
      placeholder: "TenantId",
      length: 50,
      options: dropdown?.tenants,
    },
    {
      id: "imageType",
      name: "imageType",
      type: "Image Upload",
      label: "Image Type",
      tag: "text",
      placeholder: "Image Type",
    },
    {
      id: "imageData",
      name: "imageData",
      type: "text",
      label: "Image Data",
      tag: "ImageUpload",
      placeholder: "ImageData",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const CategoryDetailsAR: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "بطاقة تعريف",
      tag: "text",
      placeholder: "Id",
      length: 24,
    },
    {
      id: "universalDescriptor",
      name: "universalDescriptor",
      type: "text",
      label: "واصف عالمي",
      tag: "text",
      placeholder: "Universal Descriptor",
      length: 50,
    },
    {
      id: "localizedText",
      name: "localizedText",
      type: "text",
      label: "النص المترجم",
      tag: "text",
      placeholder: "LocalizedText",
    },
    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: "رقم المورد",
      tag: "select",
      placeholder: "VendorId",
      length: 24,
      options: dropdown?.vendors,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "معرف المستأجر",
      tag: "select",
      placeholder: "TenantId",
      length: 50,
      options: dropdown?.tenants,
    },
    {
      id: "imageType",
      name: "imageType",
      type: "Image Upload",
      label: "نوع الصورة",
      tag: "text",
      placeholder: "Image Type",
    },
    {
      id: "imageData",
      name: "imageData",
      type: "text",
      label: "بيانات الصورة",
      tag: "ImageUpload",
      placeholder: "ImageData",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};
