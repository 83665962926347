import { useTranslation } from "react-i18next";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { DirectChargeOrderModel } from "../../../../models/order/DirectChargeOrder";

export interface IFieldsParam {
  //dropdown: {categories: [{value:string, label:string, filter1:string, filter2:string}],currencies: [{value:string, label:string, filter1:string, filter2:string}], customers:[{value:string, label:string, filter1:string, filter2:string}], fiDocTypes:[{value:string, label:string, filter1:string, filter2:string}], subCategories:[{value:string, label:string, filter1:string, filter2:string}], tenants:[{value:string, label:string, filter1:string, filter2:string}], userTypes:[{value:string, label:string, filter1:string, filter2:string}],vendorTypes:[{value:string, label:string, filter1:string, filter2:string}], vendors:[{value:string, label:string, filter1:string, filter2:string}] };
  dropdown?: any;
  state?: DirectChargeOrderModel;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const OrderMain1: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: t("order_number"),
      tag: "input",
      placeHolder: "Type Order Number",
      disabled: true,
    },
    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: t("vendor"),
      tag: "select",
      placeholder: "Vendor",
      options: VendorDD(data),
      required: true,
    },
    {
      id: "account1",
      name: "account1",
      type: "text",
      label: t("account_1"),
      tag: "text",
      placeholder: "Account1",
      required: true,
    },
    {
      id: "account2",
      name: "account2",
      type: "text",
      label: t("account_2"),
      tag: "text",
      placeholder: "Account2",
    },
    {
      id: "amount",
      name: "amount",
      type: "number",
      label: t("amount"),
      placeholder: "Total Amount",
      tag: "input",
      required: true,
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: t("customer_id"),
      tag: "select",
      placeholder: "CustomerId",
      options: data?.dropdown?.customers,
      required: true,
    },
  ];
};

export const OrderAdmin1: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: t("created_by"),
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTimeN",
      label: t("created_on"),
      tag: "DateTimeN",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: t("modified_by"),
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTimeN",
      label: t("modified_on"),
      tag: "DateTimeN",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export type dropDownFilter = (
  params: IFieldsParam
) => [{ value: string; label: string; filter1: string; filter2: string }];

export const VendorDD: dropDownFilter = (data) => {
  return data?.dropdown?.vendors;
};
