import {
  TypeBackendDropDown,
  TypeAllDropDownTables,
} from "../../../../types/BackendDropDown";
import { CardModel } from "../../../../models/MasterData/Card";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { useTranslation } from "react-i18next";

export interface IFieldsParam {
  dropdown?: TypeAllDropDownTables;
  state?: CardModel;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const CardMainEN: displayFieldsFunction = (data) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Card Id",
      tag: "inputMui",
      placeholder: "Type Card Number",
      disabled: true,
    },
    {
      id: "cardNumber",
      name: "cardNumber",
      type: "text",
      label: "Card Number",
      tag: "inputMui",
      placeholder: "Type Card Number",
      required: true,
    },
    {
      id: "serialNumber",
      name: "serialNumber",
      type: "text",
      label: "Serial Number",
      tag: "inputMui",
      placeholder: "Type Serial Number",
    },
    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: "Vendor Id",
      tag: "selectMui",
      options: data?.dropdown?.vendors,
      required: true,
    },
    {
      id: "categoryId",
      name: "categoryId",
      type: "text",
      label: "Category Id",
      tag: "selectMui",
      options: CategoryDD(data),
    },
    {
      id: "subCategoryId",
      name: "subCategoryId",
      type: "text",
      label: "Sub Category Id",
      tag: "selectMui",
      options: SubCategoryDD(data),
    },
    {
      id: "status",
      name: "status",
      type: "text",
      label: "Status",
      tag: "inputMui",
      placeholder: "Status",
      disabled: true,
    },
  ];
};

export const CardMainAR: displayFieldsFunction = (data) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "بطاقة الهوية",
      tag: "inputMui",
      placeholder: "Type Card Number",
      disabled: true,
    },
    {
      id: "cardNumber",
      name: "cardNumber",
      type: "text",
      label: "رقم البطاقة",
      tag: "inputMui",
      placeholder: "Type Card Number",
      required: true,
    },
    {
      id: "serialNumber",
      name: "serialNumber",
      type: "text",
      label: "رقم سري",
      tag: "inputMui",
      placeholder: "Type Serial Number",
    },
    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: "رقم المورد",
      tag: "selectMui",
      options: data?.dropdown?.vendors,
      required: true,
    },
    {
      id: "categoryId",
      name: "categoryId",
      type: "text",
      label: "معرف الفئة",
      tag: "selectMui",
      options: CategoryDD(data),
    },
    {
      id: "subCategoryId",
      name: "subCategoryId",
      type: "text",
      label: "معرف الفئة الفرعية",
      tag: "selectMui",
      options: SubCategoryDD(data),
    },
    {
      id: "status",
      name: "status",
      type: "text",
      label: "حالة",
      tag: "inputMui",
      placeholder: "Status",
      disabled: true,
    },
  ];
};

type dropDownFilter = (
  params: IFieldsParam
) => Array<TypeBackendDropDown> | undefined;

export const CategoryDD: dropDownFilter = (data) => {
  if (data?.state?.vendorId) {
    if (data?.dropdown) {
      return data?.dropdown?.categories.filter((x) => {
        return x.filter1 == data?.state?.vendorId;
      });
    }
  }
  return data?.dropdown?.categories;
};

export const SubCategoryDD: dropDownFilter = (data) => {
  if (data?.state?.categoryId) {
    return data?.dropdown?.subCategories.filter((x) => {
      return x.filter1 == data?.state?.categoryId;
    });
  }
  return data?.dropdown?.subCategories;
};
