import { useTranslation } from "react-i18next";
import { IFields } from "../../commonComponents/rendering/mUIReducer/IFields";
import { IAvailableCardsState } from "./Interfaces";

export interface IFieldsParam {
  //dropdown: {categories: [{value:string, label:string, filter1:string, filter2:string}],currencies: [{value:string, label:string, filter1:string, filter2:string}], customers:[{value:string, label:string, filter1:string, filter2:string}], fiDocTypes:[{value:string, label:string, filter1:string, filter2:string}], subCategories:[{value:string, label:string, filter1:string, filter2:string}], tenants:[{value:string, label:string, filter1:string, filter2:string}], userTypes:[{value:string, label:string, filter1:string, filter2:string}],vendorTypes:[{value:string, label:string, filter1:string, filter2:string}], vendors:[{value:string, label:string, filter1:string, filter2:string}] };
  dropdown?: any;
  state?: IAvailableCardsState;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const MainArray: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "VendorId",
      name: "VendorId",
      type: "text",
      label: t("vendor"),
      tag: "select",
      placeholder: "Vendor",
      options: VendorDD(data),
      required: true,
    },
  ];
};

export type dropDownFilter = (
  params: IFieldsParam
) => [{ value: string; label: string; filter1: string; filter2: string }];

export const VendorDD: dropDownFilter = (data) => {
  return data?.dropdown?.vendors;
};
