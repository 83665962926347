import React, { FC, useContext } from "react";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { CardContext } from "../Common/CardContext";
import { CardMainEN, CardMainAR } from "./Fields";
import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { useTranslation } from "react-i18next";

const MainData: FC = () => {
  const contextData = useContext(CardContext);
  const { i18n } = useTranslation();

  const fieldsToRender =
    i18n.language === "ar"
      ? CardMainAR({
          dropdown: contextData?.dropDowns,
          state: contextData?.cardData,
        })
      : CardMainEN({
          dropdown: contextData?.dropDowns,
          state: contextData?.cardData,
        });

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.cardData },
      onChange: contextData?.changeHandler,
      errorMessage: contextData?.fieldValidation?.find((x) => x.id == field.id)
        ?.message,
    };
    return newFieldObject;
  };
  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={fieldsToRender.map((field: any, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default MainData;
