import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";

export var RoleGridEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Role",
    width: 150,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
];

export var RoleGridAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "دور",
    width: 150,
  },
  {
    field: "name",
    headerName: "اسم",
    width: 200,
  },
];
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const RoleDetailsEN: displayFieldsFunction = (data) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Id",
      tag: "text",
      placeholder: "Id",
      length: 24,
    },
    {
      id: "name",
      name: "name",
      type: "text",
      label: "Name",
      tag: "text",
      placeholder: "Name",
      length: 50,
    },
  ];
};

export const RoleDetailsAR: displayFieldsFunction = (data) => {
  return [
    {
        id: "id",
        name: "id",
        type: "text",
        label: "بطاقة تعريف",
        tag: "text",
        placeholder: "Id",
        length: 24,
      },
      {
        id: "name",
        name: "name",
        type: "text",
        label: "اسم",
        tag: "text",
        placeholder: "Name",
        length: 50,
      },
  ];
};
