import React, { FC, useState, useEffect, useContext, ReactNode } from "react";
import Card from "./../../../../commonComponents/cards/overviewCard/Card";
import { useNavigate } from "react-router-dom";
import MessageLog from "../../../../commonComponents/messageLog/MessageLog";
import { useMessage, IUseMessageReturn } from "./../../../../Hooks/UseMessage";
import { useTranslation } from "react-i18next";
const OrderOverview: FC = () => {
  const useMessages: IUseMessageReturn = useMessage();
  let navigate = useNavigate();
  const [Document, setDocument] = useState<string>("");
  const { t } = useTranslation();
  const [OnlineErrorMessages, setOnlineErrorMessages] = useState<Array<string>>(
    []
  );
  function ChangeButton(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (!Document) {
      useMessages.addMessage("1", "E");
      return;
    }
    navigate(`/Order/Change/${Document}`);
  }
  function CreateButton(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    navigate(`/Order/Create`);
  }
  function DisplayButton(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (!Document) {
      useMessages.addMessage("1", "E");
      return;
    }
    navigate(`/Order/Display/${Document}`);
  }
  function DropDown() {}

  function clearMessages(e: any) {
    let type = e.target.dataset.type;
    switch (type) {
      case "success":

      case "error":
        useMessages.setError([]);
        break;
      default:
        break;
    }
  }
  return (
    <div className="container-fluid">
      <Card
        Title={t("card_orderOverview")}
        Document={Document}
        setDocument={setDocument}
        ChangeButtonFM={ChangeButton}
        CreateButtonFM={CreateButton}
        DisplayButtonFM={DisplayButton}
        DropDownFM={DropDown}
      />
    </div>
  );
};

export default OrderOverview;
