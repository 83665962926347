import React, { Dispatch, FC, SetStateAction, useState } from "react";

export interface IMessageLog {
  errorMessages: Array<string>;
  successMessages: Array<string>;
  warningMessages: Array<string>;
  setError: Dispatch<SetStateAction<string[]>>;
  setSuccess: Dispatch<SetStateAction<string[]>>;
  setWarning: Dispatch<SetStateAction<string[]>>;
}

const MessageLog: FC<IMessageLog> = ({
  errorMessages,
  successMessages,
  warningMessages,
  setError,
  setSuccess,
  setWarning,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="mt-2">
          {errorMessages.length > 0 ? (
            <div className="alert alert-danger" role="alert">
              {errorMessages.map((item, index) => {
                return (
                  <div key={index}>
                    {item} <br />
                  </div>
                );
              })}

              <button
                type="button"
                onClick={() => {
                  setError([]);
                }}
                data-type="error"
                className="btn btn-primary"
              >
                Clear
              </button>
            </div>
          ) : null}
        </div>
        <div className="mt-2">
          {warningMessages?.length > 0 ? (
            <div className="alert alert-warning" role="alert">
              {warningMessages.map((item, index) => {
                return (
                  <div key={index}>
                    {item} <br />
                  </div>
                );
              })}
              <button
                type="button"
                onClick={() => {
                  setWarning([]);
                }}
                data-type="warning"
                className="btn btn-primary"
              >
                Clear
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          {successMessages?.length > 0 ? (
            <div className="alert alert-success" role="alert">
              {successMessages.map((item, index) => {
                return (
                  <div key={index}>
                    {item} <br />
                  </div>
                );
              })}
              <button
                type="button"
                onClick={() => {
                  setSuccess([]);
                }}
                data-type="success"
                className="btn btn-primary"
              >
                Clear
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageLog;
