import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { OrderContext, IOrderContext } from "./Common/OrderContext";
import Header from "./Common/Header";
import Button from "react-bootstrap/Button";
import MainData from "./Main/MainData";
import Items from "./items/Items";
import { OrderModel } from "../../../models/order/order";
import { UseFetch } from "./../../../Hooks/UseFetchNew";
import { BaseURLs } from "../../../commonComponents/GlobalFiles/URLs";
import { useAppSelector } from "../../../redux/hooks";
import { Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import useNavigateSearch from "./../../../Hooks/useNavigationSearch";
import AdminData from "./Admin/AdminData";
import DatesData from "./Dates/DatesData";
import { useTranslation } from "react-i18next";
const OrderMain: FC = () => {
  const [operation, setOperation] = useState<string>("");
  const [orderId, setOrderId] = useState("");
  const [orderData, setOrderData] = useState<OrderModel>();
  const [key, setKey] = useState<any>("main");
  const params = useParams();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const { t } = useTranslation();
  const getOrder = UseFetch({
    url: `${BaseURLs?.Order}GetOrder`,
    method: "GET",
    loading: true,
    noToasterMessages: true,
  });
  const saveOrder = UseFetch({
    url: `${BaseURLs?.Order}SaveOrder`,
    method: "POST",
    loading: true,
    noToasterMessages: true,
  });
  const userInfo = useAppSelector((state) => state.userInfo);
  const dropDowns = useAppSelector((state) => state.dropDowns);

  useEffect(() => {
    //the use of this useEffect is to extract the search parameters

    let searchParamObject = Object.fromEntries(searchParams);
    //If there is not supplied operation then set it to Create

    if (searchParamObject?.operation) {
      setOperation(searchParamObject.operation);
    } else {
      navigateSearch("/Order", { operation: "Create" });
    }

    if (searchParamObject?.id) {
      setOrderId(searchParamObject?.id);
    }
    //added the navigate as a dependecy becuase it needed when change path is called
    //after partner is created is saved
  }, [navigate]);
  useEffect(() => {
    if (orderId) {
      getOrder.fetchData({
        params: { id: orderId },
        onSuccess: ({ data }) => {
          setOrderData(data);
          if (operation == "Create") {
            setOrderData((prevData) => ({
              ...prevData,
              id: "",
              modifiedBy: "",
              modifiedOn: undefined,
              createdBy: "",
              createdOn: undefined,
            }));

            setOrderId("");
          }
        },
        onError: (res) => {},
      });
    }
  }, [orderId]);

  useEffect(() => {
    //If the user type is a customer AND we are in the create then set the customer
    if (userInfo.userTypeId == "Customer" && operation == "Create") {
      setOrderData({ customerId: userInfo.id });
    }
    //added dependecy "userInfo.userTypeId" in order to address F5 refresh for setting the customer(above)
  }, [userInfo.userTypeId, operation]);

  function changeHandler(e: any) {
    const { name, value } = e.target;
    setOrderData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const contextState: IOrderContext = {
    operation,
    orderId,
    orderData,
    setOrderData,
    changeHandler,
    dropDowns: dropDowns.data,
  };
  return (
    <OrderContext.Provider value={contextState}>
      <Container>
        <Header>
          <Button
            className="btn btn-primary mt-1"
            style={{
              height: "3504ev",
              background: "#08bd55",
              borderColor: "#08bd55",
              fontSize: "40px",
            }}
            onClick={() => {
              saveOrder.fetchData({
                callingData: {
                  ...orderData,
                },
                onSuccess: ({ data }) => {
                  if (data) {
                    setOrderData(data);
                  }
                  if (operation === "Create") {
                    setOperation("Change");
                    setOrderId(data.id);
                    navigateSearch("/Order", {
                      id: data.id,
                      operation: "Change",
                    });
                  }
                },
                onError: (res) => {},
              });
            }}
          >
            {t("save")}
          </Button>
        </Header>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
          }}
          className="mb-3"
        >
          <Tab eventKey="main" title={t("main")}>
            <MainData />
          </Tab>
          <Tab eventKey="dates" title={t("dates")}>
            <DatesData />
          </Tab>
          <Tab eventKey="admin" title={t("admin")}>
            <AdminData />
          </Tab>
          <Tab eventKey="items" title={t("items")}>
            <Items />
          </Tab>
        </Tabs>
      </Container>
    </OrderContext.Provider>
  );
};

export default OrderMain;
