import {
  CurrencyDetailsEN,
  CurrencyDetailsAR,
  CurrencyGridEN,
  CurrencyGridAR,
} from "./Tables/Currency";
import {
  UserTypeDetailsEN,
  UserTypeDetailsAR,
  UserTypeGridEN,
  UserTypeGridAR,
} from "./Tables/UserType";
import {
  TenantDetailsEN,
  TenantDetailsAR,
  TenantGridEN,
  TenantGridAR,
} from "./Tables/Tenant";
import {
  FIDocTypeDetailsEN,
  FIDocTypeDetailsAR,
  FIDocTypeGridEN,
  FIDocTypeGridAR,
} from "./Tables/FIDocType";
import {
  VendorTypeDetailsEN,
  VendorTypeDetailsAR,
  VendorTypeGridEN,
  VendorTypeGridAR,
} from "./Tables/VendorType";
import {
  CustomerPriceDetailsEN,
  CustomerPriceDetailsAR,
  CustomerPriceGridEN,
  CustomerPriceGridAR,
} from "./Tables/MasterData/CustomerPrice";
import {
  UserDetailsEN,
  UserDetailsAR,
  UserGridEN,
  UserGridAR,
} from "./Tables/User/User";
import {
  MessageDetailsEN,
  MessageDetailsAR,
  MessageGridEN,
  MessageGridAR,
} from "./Tables/Message";
import {
  CategoryDetailsEN,
  CategoryDetailsAR,
  CategoryGridEN,
  CategoryGridAR,
} from "./Tables/Configuration/Category";
import {
  SubCategoryDetailsEN,
  SubCategoryDetailsAR,
  SubCategoryGridEN,
  SubCategoryGridAR,
} from "./Tables/Configuration/SubCategory";
import {
  VendorDetailsEN,
  VendorDetailsAR,
  VendorGridEN,
  VendorGridAR,
} from "./Tables/MasterData/Vendor";
import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../commonComponents/rendering/originalReducer/IFields";
import {
  RoleDetailsAR,
  RoleDetailsEN,
  RoleGridAR,
  RoleGridEN,
} from "./Tables/Role";
import {
  CustomerCatPriceDetailsAR,
  CustomerCatPriceDetailsEN,
  CustomerCatPriceGridAR,
  CustomerCatPriceGridEN,
} from "./Tables/Price/CustomerCatPrice";
import {
  CustomerSubCatPriceDetailsAR,
  CustomerSubCatPriceDetailsEN,
  CustomerSubCatPriceGridAR,
  CustomerSubCatPriceGridEN,
} from "./Tables/Price/CustomerSubCatPrice";

export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

//This type defines the import(state, dropdowns) and export (array of fields attributes), this is used in the detail view
export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

//This type define all table names for Detail View
export type TypeAllTablesDetailView = {
  CurrencyDetailsEN: displayFieldsFunction;
  CurrencyDetailsAR: displayFieldsFunction;
  UserTypeDetailsEN: displayFieldsFunction;
  UserTypeDetailsAR: displayFieldsFunction;
  TenantDetailsEN: displayFieldsFunction;
  TenantDetailsAR: displayFieldsFunction;
  FIDocTypeDetailsEN: displayFieldsFunction;
  FIDocTypeDetailsAR: displayFieldsFunction;
  VendorTypeDetailsEN: displayFieldsFunction;
  VendorTypeDetailsAR: displayFieldsFunction;
  MessageDetailsEN: displayFieldsFunction;
  MessageDetailsAR: displayFieldsFunction;
  CategoryDetailsEN: displayFieldsFunction;
  CategoryDetailsAR: displayFieldsFunction;
  SubCategoryDetailsEN: displayFieldsFunction;
  SubCategoryDetailsAR: displayFieldsFunction;
  VendorDetailsEN: displayFieldsFunction;
  VendorDetailsAR: displayFieldsFunction;
  CustomerPriceDetailsEN: displayFieldsFunction;
  CustomerPriceDetailsAR: displayFieldsFunction;
  UserDetailsEN: displayFieldsFunction;
  UserDetailsAR: displayFieldsFunction;
  RoleDetailsEN: displayFieldsFunction;
  RoleDetailsAR: displayFieldsFunction;
  CustomerCatPriceDetailsEN: displayFieldsFunction;
  CustomerCatPriceDetailsAR: displayFieldsFunction;
  CustomerSubCatPriceDetailsEN: displayFieldsFunction;
  CustomerSubCatPriceDetailsAR: displayFieldsFunction;
};

//This type define all table names for Grid View
export type TypeAllTablesGridView = {
  CurrencyGridEN: Array<GridColDef>;
  CurrencyGridAR: Array<GridColDef>;
  UserTypeGridEN: Array<GridColDef>;
  UserTypeGridAR: Array<GridColDef>;
  TenantGridEN: Array<GridColDef>;
  TenantGridAR: Array<GridColDef>;
  FIDocTypeGridEN: Array<GridColDef>;
  FIDocTypeGridAR: Array<GridColDef>;
  VendorTypeGridEN: Array<GridColDef>;
  VendorTypeGridAR: Array<GridColDef>;
  MessageGridEN: Array<GridColDef>;
  MessageGridAR: Array<GridColDef>;
  CategoryGridEN: Array<GridColDef>;
  CategoryGridAR: Array<GridColDef>;
  SubCategoryGridEN: Array<GridColDef>;
  SubCategoryGridAR: Array<GridColDef>;
  VendorGridEN: Array<GridColDef>;
  VendorGridAR: Array<GridColDef>;
  CustomerPriceGridEN: Array<GridColDef>;
  CustomerPriceGridAR: Array<GridColDef>;
  UserGridEN: Array<GridColDef>;
  UserGridAR: Array<GridColDef>;
  RoleGridEN: Array<GridColDef>;
  RoleGridAR: Array<GridColDef>;
  CustomerCatPriceGridEN: Array<GridColDef>;
  CustomerCatPriceGridAR: Array<GridColDef>;
  CustomerSubCatPriceGridEN: Array<GridColDef>;
  CustomerSubCatPriceGridAR: Array<GridColDef>;
};

export var TableGridFields: TypeAllTablesGridView = {
  CurrencyGridEN,
  CurrencyGridAR,
  UserTypeGridEN,
  UserTypeGridAR,
  TenantGridEN,
  TenantGridAR,
  VendorTypeGridEN,
  VendorTypeGridAR,
  FIDocTypeGridEN,
  FIDocTypeGridAR,
  MessageGridEN,
  MessageGridAR,
  CategoryGridEN,
  CategoryGridAR,
  SubCategoryGridEN,
  SubCategoryGridAR,
  VendorGridEN,
  VendorGridAR,
  CustomerPriceGridEN,
  CustomerPriceGridAR,
  UserGridEN,
  UserGridAR,
  RoleGridEN,
  RoleGridAR,
  CustomerCatPriceGridEN,
  CustomerCatPriceGridAR,
  CustomerSubCatPriceGridEN,
  CustomerSubCatPriceGridAR,
};

export var TableDetails: TypeAllTablesDetailView = {
  CurrencyDetailsEN,
  CurrencyDetailsAR,
  UserTypeDetailsEN,
  UserTypeDetailsAR,
  TenantDetailsEN,
  TenantDetailsAR,
  FIDocTypeDetailsEN,
  FIDocTypeDetailsAR,
  VendorTypeDetailsEN,
  VendorTypeDetailsAR,
  MessageDetailsEN,
  MessageDetailsAR,
  CategoryDetailsEN,
  CategoryDetailsAR,
  SubCategoryDetailsEN,
  SubCategoryDetailsAR,
  VendorDetailsEN,
  VendorDetailsAR,
  CustomerPriceDetailsEN,
  CustomerPriceDetailsAR,
  UserDetailsEN,
  UserDetailsAR,
  RoleDetailsEN,
  RoleDetailsAR,
  CustomerCatPriceDetailsEN,
  CustomerCatPriceDetailsAR,
  CustomerSubCatPriceDetailsEN,
  CustomerSubCatPriceDetailsAR,
};
