import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import {
//   MDBBtn,
//   MDBContainer,
//   MDBCard,
//   MDBCardBody,
//   MDBCardImage,
//   MDBRow,
//   MDBCol,
//   MDBIcon,
//   MDBInput,
//   MDBCheckbox,
// } from "mdb-react-ui-kit";

interface CardProps {
  cardBody: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  onSubmit?: () => void;
}

function LoginCard({ cardBody, onSubmit, style }: CardProps) {
  return (
    <Container style={style} fluid className="p-3 my-5">
      <Row>
        <Col className="d-none d-md-block" xs="10" md="6">
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
            className="img-fluid"
            alt="Phone image"
          />
        </Col>
        <Col xs="12" md="6">
          {cardBody}
        </Col>
      </Row>
    </Container>
  );
}

export default LoginCard;
