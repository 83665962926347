import React, { FC, useState, useEffect, useContext, ReactNode } from "react";
import { OrderContext } from "../Common/OrderContext";
import { OrderAdmin1EN, OrderAdmin1AR } from "./Fields";
import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { useTranslation } from "react-i18next";

const AdminData: FC = () => {
  const contextData = useContext(OrderContext);
  const [admin, setAdmin] = useState<Array<IFields>>([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "ar") {
      setAdmin(
        OrderAdmin1AR({
          dropdown: contextData?.dropDowns,
        })
      );
    } else {
      setAdmin(
        OrderAdmin1EN({
          dropdown: contextData?.dropDowns,
        })
      );
    }
  }, [contextData?.dropDowns]);
  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.orderData },
      onChange: contextData?.changeHandler,
    };
    return newFieldObject;
  };
  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={admin.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default AdminData;
