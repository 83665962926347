import { useTranslation } from "react-i18next";
import { IFields } from "../../../commonComponents/rendering/originalReducer/IFields";
import { IOrderWorkbenchState } from "./Interfaces";

export interface IFieldsParam {
  //dropdown: {categories: [{value:string, label:string, filter1:string, filter2:string}],currencies: [{value:string, label:string, filter1:string, filter2:string}], customers:[{value:string, label:string, filter1:string, filter2:string}], fiDocTypes:[{value:string, label:string, filter1:string, filter2:string}], subCategories:[{value:string, label:string, filter1:string, filter2:string}], tenants:[{value:string, label:string, filter1:string, filter2:string}], userTypes:[{value:string, label:string, filter1:string, filter2:string}],vendorTypes:[{value:string, label:string, filter1:string, filter2:string}], vendors:[{value:string, label:string, filter1:string, filter2:string}] };
  dropdown?: any;
  state?: IOrderWorkbenchState;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const ReportFields: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "Id",
      name: "Id",
      type: "text",
      label: t("order_number"),
      tag: "input",
      placeholder: "Type Order Number",
    },
    {
      id: "OrderDT",
      name: "OrderDT",
      type: "text",
      label: t("from_order_dt"),
      tag: "DateTimeN",
      placeholder: "Order Date",
    },
    {
      id: "VendorId",
      name: "VendorId",
      type: "text",
      label: t("vendor"),
      tag: "select",
      placeholder: "Vendor",
      options: VendorDD(data),
    },
    {
      id: "CardCategoryId",
      name: "CardCategoryId",
      type: "text",
      label: t("card_category_id"),
      tag: "select",
      placeholder: "Card Category",
      options: CategoryDD(data),
    },
    {
      id: "CardSubCategoryId",
      name: "CardSubCategoryId",
      type: "text",
      label: t("card_sub_category_id"),
      tag: "select",
      placeholder: "Card Sub Category",
      options: SubCategoryDD(data),
    },
    {
      id: "CustomerId",
      name: "CustomerId",
      type: "text",
      label: t("customer_id"),
      tag: "select",
      placeholder: "CustomerId",
      options: data?.dropdown?.customers,
    },
  ];
};

export type dropDownFilter = (
  params: IFieldsParam
) => [{ value: string; label: string; filter1: string; filter2: string }];

export const VendorDD: dropDownFilter = (data) => {
  if (data?.dropdown) {
    return data?.dropdown?.vendors;
  }
};

export const CategoryDD: dropDownFilter = (data) => {
  if (data?.state?.VendorId) {
    let cc: [
      { value: string; label: string; filter1: string; filter2: string }
    ];

    cc = data?.dropdown?.categories;

    return cc.filter((x) => {
      return x.filter1 == data?.state?.VendorId;
    });
  }
  if (data?.dropdown) {
    return data?.dropdown?.categories;
  }
};

export const SubCategoryDD: dropDownFilter = (data) => {
  if (data?.state?.CardCategoryId) {
    let cc: [
      { value: string; label: string; filter1: string; filter2: string }
    ];

    cc = data?.dropdown.subCategories;

    return cc.filter((x) => {
      return x.filter1 == data?.state?.CardCategoryId;
    });
  }
  if (data?.dropdown) {
    return data?.dropdown.subCategories;
  }
};
