import React, { useCallback, useMemo } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { CardModel } from "../models/MasterData/Card";
export const useSchema = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const LoginSchema = useMemo(() => {
    return yup.object().shape({
      UserName: yup
        .string()
        .required(
          t("validations.required", { fieldName: "User Name" }).toString()
        ),
      Password: yup
        .string()
        .required(
          t("validations.required", { fieldName: "Password" }).toString()
        )
        .min(
          2,
          t("validations.min", { fieldName: "Password", min: 10 }).toString()
        ),
      Language: yup
        .string()
        .required(
          t("validations.required", { fieldName: "Language20" }).toString()
        ),
    });
  }, [language]);

  const OrderSchema = useMemo(() => {
    return yup.object().shape({
      // orderData: yup
      //   .string()
      //   .required(
      //     t("validations.required", { fieldName: "Order Data" }).toString()
      //   ),
      vendorId: yup
        .string()
        .required(
          t("validations.required", { fieldName: "Vendor Id" }).toString()
        ),
      cardCategoryId: yup.string().required(
        t("validations.required", {
          fieldName: "Card Category Id",
        }).toString()
      ),
      cardSubCategoryId: yup.string().required(
        t("validations.required", {
          fieldName: "Card SubCategory Id",
        }).toString()
      ),
      customerId: yup
        .string()
        .required(
          t("validations.required", { fieldName: "Customer Id" }).toString()
        ),
      numberCards: yup
        .string()
        .required(
          t("validations.required", { fieldName: "Number Cards" }).toString()
        ),
      orderDT: yup
        .date()
        .required(
          t("validations.required", { fieldName: "order DT" }).toString()
        ),
    });
  }, [language]);

  interface CardValidationInterface {
    cardNumber: string;
    categoryId: string;
    subCategoryId: string;
    vendorId: string;
  }

  const CardSchema: yup.SchemaOf<CardValidationInterface> = useMemo(() => {
    return yup.object().shape({
      cardNumber: yup
        .string()
        .required(
          t("validations.required", { fieldName: "Card Number" }).toString()
        ),
      categoryId: yup.string().required(
        t("validations.required", {
          fieldName: "Card Category",
        }).toString()
      ),
      subCategoryId: yup.string().required(
        t("validations.required", {
          fieldName: " Sub Category",
        }).toString()
      ),
      vendorId: yup.string().required(
        t("validations.required", {
          fieldName: " Vendor",
        }).toString()
      ),
    });
  }, [language]);

  interface CustomerValidationInterface {
    id: string;
    firstName: string;
    lastName: string;
  }

  const CustomerSchema: yup.SchemaOf<CustomerValidationInterface> =
    useMemo(() => {
      return yup.object().shape({
        id: yup
          .string()
          .required(
            t("validations.required", { fieldName: "Customer Id" }).toString()
          ),
        firstName: yup.string().required(
          t("validations.required", {
            fieldName: "First Name",
          }).toString()
        ),
        lastName: yup.string().required(
          t("validations.required", {
            fieldName: " Last Name",
          }).toString()
        ),
      });
    }, [language]);

  return { LoginSchema, OrderSchema, CardSchema, CustomerSchema };
};
