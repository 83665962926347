import React, { FC, useState } from "react";
import "./Overview.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
interface ICardProps {
  Title: string;
  setDocument: React.Dispatch<React.SetStateAction<string>>;
  Document: string;
  DisplayButtonFM?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;

  ChangeButtonFM?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;

  CreateButtonFM?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  DropDownFM?: () => void;
}
const OrderOverview: FC<ICardProps> = ({
  Title,
  Document,
  setDocument,
  DisplayButtonFM,
  ChangeButtonFM,
  CreateButtonFM,
  DropDownFM,
}) => {
  return (
    <div className="container-fluid">
      <div className="overviewCard">
        <div className="overviewCardTitle">{Title}</div>

        <div className="overviewCardInputPlusDDButton">
          <input
            onFocus={(e) => {
              e.stopPropagation();
            }}
            value={Document}
            onChange={(e) => {
              let val = e.target.value;
              setDocument(val);
            }}
            type="text"
            id="DocId"
            style={{
              borderRadius: 8,
              padding: 8,
              //              height: 50,
              fontSize: 20,
            }}
          />
          <button className="btn btn-primary m-2" onClick={DropDownFM}>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </div>

        <div className="overviewCardButtons">
          <button className="btn btn-primary m-2" onClick={DisplayButtonFM}>
            Display
          </button>
          <button className="btn btn-primary m-2" onClick={ChangeButtonFM}>
            Change
          </button>
          <button className="btn  btn-primary m-2" onClick={CreateButtonFM}>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderOverview;
