import React, { FC } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenSquare,
  faTrash,
  faBinoculars,
} from "@fortawesome/free-solid-svg-icons";
export interface IGridAction {
  handleButtonClickGrid?: (data: {}) => void;
  dataRecord: {};
}

const GridAction: FC<IGridAction> = ({ handleButtonClickGrid, dataRecord }) => {
  return (
    <React.Fragment>
      <Button
        className="btn-danger"
        //       disabled={false}
        onClick={() => {
          if (typeof handleButtonClickGrid === "function") {
            handleButtonClickGrid(dataRecord);
          }
        }}
      >
        <FontAwesomeIcon icon={faBinoculars} />
      </Button>

      <Button
        className="mx-2"
        //        style={{ background: "#008000", borderColor: "#008000" }}
        data-op="Change"
        onClick={() => {
          if (typeof handleButtonClickGrid === "function") {
            handleButtonClickGrid(dataRecord);
          }
        }}
        //       disabled={false}
      >
        <FontAwesomeIcon icon={faPenSquare} />
      </Button>
      <Button
        className="btn-danger"
        style={{ background: "#dc3545", borderColor: "#dc3545" }}
        onClick={() => {
          if (typeof handleButtonClickGrid === "function") {
            handleButtonClickGrid(dataRecord);
          }
        }}
        //        disabled={false}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </React.Fragment>
  );
};

export default GridAction;
