import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "../commonComponents/cards/fieldsCard/Card/Card";
import { IFields } from "../commonComponents/rendering/originalReducer/IFields";
import { Reducer } from "../commonComponents/rendering/originalReducer/Reducer";
import { UserModel } from "../models/User/User";
import { MainArray, AdminArray, FinancialArray } from "./Fields";
import { newUserData, LogOut } from "../redux/features/UserSlice";
import { BaseURLs } from "../commonComponents/GlobalFiles/URLs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { useTranslation } from "react-i18next";
import { UseFetch } from "../Hooks/UseFetchNew";
import { useMessageI18n } from "../Hooks/useMessageI18n";
const UserSettings: FC = () => {
  const dropDowns = useAppSelector((state) => state.dropDowns);
  const [user, setUser] = useState<UserModel>();
  const [key, setKey] = useState<any>("main");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const useMessagesI18n = useMessageI18n();

  const userId = localStorage.getItem("rc_id");
  const updateUser = UseFetch({
    url: `${BaseURLs?.User}UpdateUser`,
    method: "PUT",
    loading: true,
    noToasterMessages: true,
  });

  const getUser = UseFetch({
    url: `${BaseURLs?.User}GetUser`,
    method: "GET",
    loading: true,
    noToasterMessages: true,
  });

  useEffect(() => {
    if (userId) {
      getUser.fetchData({
        params: { id: userId },
        onSuccess: ({ data }) => {
          debugger;
          setUser(data);
        },
        onError: (res) => {},
      });
    }
  }, [userId]);

  const mainFieldsToRender = MainArray({
    dropdown: dropDowns,
    state: user,
  });
  const adminFieldsToRender = AdminArray({
    dropdown: dropDowns,
    state: user,
  });
  const financialFieldsToRender = FinancialArray({
    dropdown: dropDowns.data,
    state: user,
  });

  function changeHandler(e: any) {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      onChange: changeHandler,
      dataSource: { ...user },
    };
    if (newFieldObject.keyField) {
      newFieldObject.disabled = true;
    }
    return newFieldObject;
  };

  return (
    <Container>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
      >
        <Tab eventKey="main" title={t("main")}>
          <Card
            cardHeaderProps={{
              headerLabel: "",
            }}
            cardBody={mainFieldsToRender.map((field, index) => (
              <Reducer key={index} {...mergedFieldData(field)} />
            ))}
            style={{ width: "60%" }}
          />
        </Tab>

        <Tab eventKey="financial" title={t("financial")}>
          <div style={{ display: "flex" }}>
            <Card
              cardHeaderProps={{
                headerLabel: "",
              }}
              cardBody={financialFieldsToRender.map((field, index) => (
                <Reducer key={index} {...mergedFieldData(field)} />
              ))}
              style={{ width: "60%" }}
            />
          </div>
        </Tab>
        <Tab eventKey="admin" title={t("admin")}>
          <div style={{ display: "flex" }}>
            <Card
              cardHeaderProps={{
                headerLabel: "",
              }}
              cardBody={adminFieldsToRender.map((field, index) => (
                <Reducer key={index} {...mergedFieldData(field)} />
              ))}
              style={{ width: "60%" }}
            />
          </div>
        </Tab>
      </Tabs>

      <Button
        style={{
          background: "#E74C3C",
          fontSize: "40px",
        }}
        onClick={() => {
          dispatch(LogOut());
        }}
      >
        {t("log_out")}
      </Button>
      <Button
        style={{
          background: "#45e73c",
          fontSize: "40px",
          margin: "10px",
        }}
        onClick={() => {
          if (!user) {
            useMessagesI18n.addMessage("5", "E");
            return;
          }
          updateUser.fetchData({
            callingData: {
              ...user,
            },
            onSuccess: ({ data }) => {
              if (data) {
                dispatch(newUserData(data));
                setUser(data);
              }
            },
            onError: (res) => {},
          });
        }}
      >
        {t("save")}
      </Button>
    </Container>
  );
};

export default UserSettings;
