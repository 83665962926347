import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import CardHeader, { ICardHeaderProps } from "./CardHeader";

export interface CardProps {
  cardHeaderProps: ICardHeaderProps;
  cardBody: JSX.Element[] | JSX.Element;
}

export function CardV2({ cardBody, cardHeaderProps }: CardProps) {
  return (
    <Container style={{ width: "40%", margin: 0 }}>
      <Row style={{ width: "100%" }} className="my-5">
        <Col xs="12">{cardBody}</Col>
      </Row>
    </Container>
  );
}
export default CardV2;
