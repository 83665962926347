import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../../../commonComponents/rendering/originalReducer/IFields";

export var CustomerPriceGridEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Customer Price",
    width: 100,
  },
  {
    field: "customerId",
    headerName: "Customer Id",
    width: 200,
  },
  {
    field: "subCategoryId",
    headerName: "Sub Category Id",
    width: 200,
  },
  {
    field: "price",
    headerName: "Price",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "Currency Id",
    width: 200,
  },
  {
    field: "tenantId",
    headerName: "Tenant Id",
    width: 200,
  },
];

export var CustomerPriceGridAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "سعر العميل",
    width: 100,
  },
  {
    field: "customerId",
    headerName: "هوية الزبون",
    width: 200,
  },
  {
    field: "subCategoryId",
    headerName: "معرف الفئة الفرعية",
    width: 200,
  },
  {
    field: "price",
    headerName: "سعر",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "معرف العملة",
    width: 200,
  },
  {
    field: "tenantId",
    headerName: "معرف المستأجر",
    width: 200,
  },
];
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const CustomerPriceDetailsEN: displayFieldsFunction = ({
  dropdown,
  state,
}) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Id",
      tag: "text",
      placeholder: "Id",
      length: 24,
      disabled: true,
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "Customer Id",
      tag: "select",
      options: dropdown?.customers,
    },
    {
      id: "subCategoryId",
      name: "subCategoryId",
      type: "text",
      label: "Sub Category Id",
      tag: "select",
      options: dropdown?.subCategories,
    },
    {
      id: "price",
      name: "price",
      type: "text",
      label: "Price",
      tag: "text",
      placeholder: "Price",
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "Currency",
      tag: "select",
      placeholder: "Currency",
      options: dropdown?.currencies,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const CustomerPriceDetailsAR: displayFieldsFunction = ({
  dropdown,
  state,
}) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "بطاقة تعريف",
      tag: "text",
      placeholder: "Id",
      length: 24,
      disabled: true,
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "هوية الزبون",
      tag: "select",
      options: dropdown?.customers,
    },
    {
      id: "subCategoryId",
      name: "subCategoryId",
      type: "text",
      label: "معرف الفئة الفرعية",
      tag: "select",
      options: dropdown?.subCategories,
    },
    {
      id: "price",
      name: "price",
      type: "text",
      label: "سعر",
      tag: "text",
      placeholder: "Price",
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "عملة",
      tag: "select",
      placeholder: "Currency",
      options: dropdown?.currencies,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};
