import { MessageContext } from "./../admin/providers/MessageProvider";
import { useState, useContext } from "react";
import Axios from "axios";
import { Method } from "axios";
import TypeBackendMessags from "../models/types/BackendMessages";
import { useLoading } from "../commonComponents/loaders/newLoader/LoadingProvder";
let allBackendMessages: Array<TypeBackendMessags>;
interface IonCallbackInputParam {
  data?: any;
}

interface IgetDataInputParam {
  callingData?: any;
  params?: any;
  onSuccess?: (response: IonCallbackInputParam) => void;
  newURL?: string;
  onError?: (response: IonCallbackInputParam) => void;
  onAfterCall?: (response: IonCallbackInputParam) => void;
}

interface IHookParam {
  url: string;
  method: Method | string;
  loading?: boolean;
  noToasterMessages?: boolean;
  baseURL?: string;
}

//FetchData Funtion Type Def, it takes the following parameters:
//1-Calling data, ie data passed to API call
//2-OnSuccess, a callback function called on success
//3-OnError, , a callback function called on error
//4-newURL, API Url to be called, that replaces the API suppield when useFetch is instantiated
//void return type becuse it does not return anything it just update the various states
export type TgetDataFunction = (
  GetDataParams: IgetDataInputParam
) => Promise<any>;

//UseFetch Funtion Type Def, it takes the following parameters:
//1-URL, URL to call API
//2-Method, Post, get etc..
//3-noToasterMessages, bool not to return toast messages
//it returns the following:
//1-The FetchData Function of type "TgetDataFunction"
//2-outData, setOutData  API Data from react state
//3-error, setError state
//4-success, setSuccess state

export interface IUseFetchReturn {
  fetchData: TgetDataFunction;
  outData: any;
  setOutData: React.Dispatch<React.SetStateAction<{}>>;
  error: string[];
  setError: React.Dispatch<React.SetStateAction<Array<string>>>;
  success: string[];
  setSuccess: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export type Thook = (HookParams: IHookParam) => IUseFetchReturn;

function replaceBracketedValues(obj: any): any {
  //function that recursively
  //searches through an object or
  // an array of objects to find
  //strings that match the pattern (value):some text.

  if (Array.isArray(obj)) {
    // Handle array case
    return obj.map(replaceBracketedValues);
  } else if (typeof obj === "object" && obj !== null) {
    // Handle object case
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      newObj[key] = replaceBracketedValues(obj[key]);
    }
    return newObj;
  } else if (typeof obj === "string") {
    // Handle string case
    const match = obj.match(/\(([^)]+)\)/);
    return match ? match[1] : obj;
  } else {
    // Return primitive types as is
    return obj;
  }
}

export const UseFetch: Thook = ({
  url,
  method,
  loading,
  noToasterMessages,
  baseURL,
}) => {
  const [outData, setOutData] = useState({});
  const [error, setError] = useState<Array<string>>([]);
  const [success, setSuccess] = useState<Array<string>>([]);
  const { startLoading, stopLoading } = useLoading();
  //  const userinfo = useUser();
  const messageContext = useContext(MessageContext);
  const fetchData: TgetDataFunction = async ({
    callingData,
    params,
    newURL,
    onSuccess,
    onError,
    onAfterCall,
  }) => {
    const usedUrl: string = newURL ? newURL : url;

    try {
      startLoading();
      // if (loading) {
      //   // if (userinfo.debugMode) {
      //   //   startLoadingWithUrl!(usedUrl);
      //   // } else {
      //   startLoading();
      //   //        }
      // }

      const { data: response } = await Axios({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tload_token"),
        },
        url: usedUrl,
        method: method,
        //        data: replaceBracketedValues(callingData),
        data: callingData,
        params: replaceBracketedValues(params),
        // data: callingData,
        // params: params,
        baseURL: baseURL,
      });
      if (onAfterCall) {
        onAfterCall(response);
      }
      if (response.data != null) {
        setOutData(response.data);
      }
      debugger;
      allBackendMessages = response?.messages || [];
      var foundError = allBackendMessages.some((x) => x.messageType === "E");
      var foundSuccess = allBackendMessages.some((x) => x.messageType === "S");

      if (foundError) {
        for (const message of response.messages) {
          if (message.messageType === "E") {
            !noToasterMessages && toastr.error(message.messageText);
            messageContext?.setError((current) => {
              return [...current, message.messageText];
            });
          }
        }
      }
      if (foundSuccess) {
        for (const message of response.messages) {
          if (message.messageType === "S") {
            !noToasterMessages && toastr.success(message.messageText);
            messageContext?.setSuccess((current) => {
              return [...current, message.messageText];
            });
          }
        }
      }
      if (foundError) {
        if (onError) {
          onError(response);
        }
      } else {
        if (onSuccess) {
          onSuccess(response);
        }
      }

      return response.data;
    } catch (error: any) {
      if (error?.response?.data != null) {
        setOutData(error.response.data.data);

        if (error?.response?.data?.messages != null) {
          for (const message of error.response.data.messages) {
            !noToasterMessages && toastr.error(message.messageText);
            messageContext?.setError((current) => {
              return [...current, message.messageText];
            });
          }
        }

        if (error?.response?.data?.errors != null) {
          messageContext?.setError((current) => {
            return [...current, JSON.stringify(error?.response?.data?.errors)];
          });
        }
      }
      if (onError) {
        onError(error?.response);
      }
      if (error.message) {
        messageContext?.setError((current) => {
          return [...current, error.message];
        });
      }

      if (onAfterCall) {
        onAfterCall(error?.response);
      }
      if (error?.response?.data.data) {
        return error.response.data.data;
      } else {
        return null;
      }
    } finally {
      stopLoading();
    }
  };

  return {
    fetchData,
    outData,
    setOutData,
    error,
    setError,
    success,
    setSuccess,
  };
};
