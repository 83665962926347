import { useTranslation } from "react-i18next";
import { IFields } from "../../../commonComponents/rendering/originalReducer/IFields";
import {
  TypeBackendDropDown,
  TypeAllDropDownTables,
} from "../../../types/BackendDropDown";
import { ICardWorkbenchState } from "./Interfaces";

export interface IFieldsParam {
  dropdown: TypeAllDropDownTables;
  state?: ICardWorkbenchState;
}

type dropDownFilter = (params: IFieldsParam) => Array<TypeBackendDropDown>;

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const ReportFields: displayFieldsFunction = (data) => {
  const { t } = useTranslation();

  return [
    {
      id: "cardNumber",
      name: "cardNumber",
      type: "text",
      label: t("card_number"),
      tag: "inputMui",
      placeholder: "Type Card Number",
      required: true,
    },
    {
      id: "VendorId",
      name: "VendorId",
      type: "text",
      label: t("vendor"),
      tag: "selectMui",
      placeholder: "Vendor",
      options: VendorDD(data),
      required: true,
    },
    {
      id: "CategoryId",
      name: "CategoryId",
      type: "text",
      label: t("category"),
      tag: "selectMui",
      placeholder: "Card Category",
      required: true,
      options: CategoryDD(data),
    },
    {
      id: "SubCategoryId",
      name: "SubCategoryId",
      type: "text",
      label: t("sub_category"),
      tag: "selectMui",
      placeholder: "Card Sub Category",
      options: SubCategoryDD(data),
      required: true,
    },
    {
      id: "status",
      name: "status",
      type: "text",
      label: t("status"),
      tag: "selectMui",
      placeholder: "Status",
      options: statusDD,
      required: true,
    },
  ];
};

let statusDD: Array<{ value: string; label: string }> = [
  { value: "Available", label: "Available" },
  { value: "Used", label: "Used" },
];

export const VendorDD: dropDownFilter = (data) => {
  return data.dropdown.vendors;
};

export const CategoryDD: dropDownFilter = (data) => {
  if (data?.state?.VendorId) {
    return data?.dropdown?.categories.filter((x) => {
      return x.filter1 == data?.state?.VendorId;
    });
  }
  return data?.dropdown?.categories;
};

export const SubCategoryDD: dropDownFilter = (data) => {
  if (data?.state?.CategoryId) {
    return data?.dropdown.subCategories.filter((x) => {
      return x.filter1 == data?.state?.CategoryId;
    });
  }
  return data?.dropdown.subCategories;
};
