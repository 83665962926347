import { useTranslation } from "react-i18next";
import { simpleGridFields } from "../../commonComponents/grids/simpleGrid/interfaces";

export const AvailableCardItem: simpleGridFields = () => {
  const { t } = useTranslation();
  return [
    {
      id: "vendorId",
      label: t("vendor_id"),
    },
    {
      id: "categoryId",
      label: t("category_id"),
    },
    {
      id: "subCategoryId",
      label: t("sub_category_id"),
    },
    {
      id: "available",
      label: t("available"),
    },
  ];
};
