import { useTranslation } from "react-i18next";
import { IFields } from "../../../commonComponents/rendering/mUIReducer/IFields";
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const ConfigTables: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "table",
      name: "table",
      type: "text",
      label: t("table_name"),
      tag: "select",
      options: [
        { value: "Currency", label: t("currency") },
        { value: "UserType", label: t("user_type") },
        { value: "Tenant", label: t("tenant") },
        { value: "FIDocType", label: t("fidoc_type") },
        { value: "VendorType", label: t("vendor_type") },
        { value: "Message", label: t("message") },
        { value: "Category", label: t("category") },
        { value: "SubCategory", label: t("sub_category") },
        { value: "Vendor", label: t("vendor") },
        { value: "CustomerPrice", label: t("customer_price") },
        { value: "User", label: t("user") },
        { value: "Role", label: t("role") },
      ],
    },
  ];
};
