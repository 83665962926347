import React, { FC, useState } from "react";
import Button from "react-bootstrap/Button";
import { ISimpleGridFields } from "./interfaces";
import HeaderGrid from "./GridHeader";
import GridAction from "./GridAction";

export interface ISimpleGrid {
  Fields: Array<ISimpleGridFields>;
  data: Array<any>;
  ActionColumn?: boolean;
  handleButtonClickGrid?: (data: {}) => void;
}

const SimpleGrid: FC<ISimpleGrid> = ({
  Fields,
  data,
  ActionColumn,
  handleButtonClickGrid,
}) => {
  return (
    <table className="table table-striped">
      <HeaderGrid Fields={Fields} ActionColumn={ActionColumn} />
      <tbody>
        {data?.map((dataRecord, i) => {
          return (
            <tr key={i}>
              {Fields.map((field, i1) => {
                return <td key={i1}>{dataRecord[field.id]}</td>;
              })}
              {ActionColumn && (
                <td offset-2>
                  <GridAction
                    handleButtonClickGrid={handleButtonClickGrid}
                    dataRecord={dataRecord}
                  />
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SimpleGrid;
