import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import CardHeader, { ICardHeaderProps } from "./CardHeader";

export interface CardProps {
  cardHeaderProps: ICardHeaderProps;
  cardBody: JSX.Element[] | JSX.Element;
  style?: React.CSSProperties;
}

export function Card({ cardBody, cardHeaderProps, style }: CardProps) {
  return (
    <Container style={style} fluid className="my-5 w-100">
      {/* <CardHeader {...cardHeaderProps} /> */}
      {/* <div className="cardBody">{cardBody}</div> */}
      <Row>
        <Col xs="12">{cardBody}</Col>
      </Row>
    </Container>
  );
}
export default Card;
