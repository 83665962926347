import { TypeAllDropDownTables } from "./../../../../types/BackendDropDown";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { CustomerModel } from "../../../../models/MasterData/Customer";
import { useTranslation } from "react-i18next";
import { UserModel } from "../../../../models/User/User";

export interface IFieldsParam {
  dropdown?: TypeAllDropDownTables;
  state?: UserModel;
  disabled?: boolean;
  disabled2?: boolean;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const CustomerMainEN: displayFieldsFunction = (data) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Customer Id",
      tag: "inputMui",
      placeholder: "Type Order Number",
      keyField: true,
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "First Name",
      tag: "inputMui",
      placeholder: "Type First Name",
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "Last Name",
      tag: "inputMui",
      placeholder: "Type Last Name",
    },
    {
      id: "password",
      name: "password",
      type: "text",
      label: "Password",
      tag: "inputMui",
      placeholder: "Type Password",
    },
    {
      id: "updatePassword",
      name: "updatePassword",
      type: "checkbox",
      label: "Update Password",
      tag: "Checkbox",
      placeholder: "Update Password",
      disabled: data.disabled2,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "Tenant Id",
      tag: "select",
      placeholder: "TenantId",
      length: 50,
      options: data.dropdown?.tenants,
      disabled: data.disabled,
    },
    {
      id: "active",
      name: "active",
      type: "checkbox",
      label: "Active",
      tag: "Checkbox",
      placeholder: "Active",
    },
    {
      id: "email",
      name: "email",
      type: "text",
      label: "Email",
      tag: "text",
      placeholder: "Email",
    },
    {
      id: "phoneNumber",
      name: "phoneNumber",
      type: "text",
      label: "Phone Number",
      tag: "text",
    },
    {
      id: "validToDT",
      name: "validToDT",
      type: "text",
      label: "Valid To DT",
      tag: "DateTimeN",
      placeholder: "Valid To DT",
    },
    {
      id: "balance",
      name: "balance",
      type: "number",
      label: "Balance",
      placeholder: "Balance",
      tag: "inputMui",
      disabled: true,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "Currency",
      tag: "select",
      options: data?.dropdown?.currencies,
      disabled: data.disabled,
    },
    {
      id: "orderBlock",
      name: "orderBlock",
      type: "text",
      label: "Order Block",
      tag: "CheckboxMui",
    },
    {
      id: "allowNegativeBalance",
      name: "allowNegativeBalance",
      type: "text",
      label: "Allow Negative Balance",
      tag: "CheckboxMui",
    },
  ];
};

export const CustomerMainAR: displayFieldsFunction = (data) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "هوية الزبون",
      tag: "inputMui",
      placeholder: "Type Order Number",
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "الاسم الأول",
      tag: "inputMui",
      placeholder: "Type First Name",
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "اسم العائلة",
      tag: "inputMui",
      placeholder: "Type Last Name",
    },
    {
      id: "password",
      name: "password",
      type: "text",
      label: "كلمة المرور",
      tag: "inputMui",
      placeholder: "Type Password",
    },
    {
      id: "updatePassword",
      name: "updatePassword",
      type: "checkbox",
      label: "تطوير كلمة السر",
      tag: "Checkbox",
      placeholder: "Update Password",
      disabled: data.disabled2,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "معرف المستأجر",
      tag: "select",
      placeholder: "TenantId",
      length: 50,
      options: data.dropdown?.tenants,
      disabled: data.disabled,
    },
    {
      id: "active",
      name: "active",
      type: "checkbox",
      label: "نشيط",
      tag: "Checkbox",
      placeholder: "Active",
    },
    {
      id: "email",
      name: "email",
      type: "text",
      label: "بريد إلكتروني",
      tag: "text",
      placeholder: "Email",
    },
    {
      id: "phoneNumber",
      name: "phoneNumber",
      type: "text",
      label: "رقم التليفون",
      tag: "text",
    },
    {
      id: "validToDT",
      name: "validToDT",
      type: "text",
      label: "صالحة حتى الآن",
      tag: "DateTime",
      placeholder: "Valid To DT",
    },
    {
      id: "balance",
      name: "balance",
      type: "number",
      label: "توازن",
      placeholder: "Balance",
      tag: "inputMui",
      disabled: true,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "عملة",
      tag: "selectMui",
      options: data?.dropdown?.currencies,
    },
    {
      id: "orderBlock",
      name: "orderBlock",
      type: "text",
      label: "كتلة الطلب",
      tag: "CheckboxMui",
    },
    {
      id: "allowNegativeBalance",
      name: "allowNegativeBalance",
      type: "text",
      label: "السماح بالرصيد السلبي",
      tag: "CheckboxMui",
    },
  ];
};
