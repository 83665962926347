import {
  createContext,
  useState,
  useContext,
  FC,
  PropsWithChildren,
} from "react";
import "./Loader.scss";

export interface LoadingContextType {
  loading: boolean;
  startLoading: () => void;
  startLoadingWithUrl: (urlName: string) => void;
  stopLoading: () => void;
}
const initialState = {
  loading: false,
  startLoading: () => {},
  startLoadingWithUrl: () => {},
  stopLoading: () => {},
};
const LoadingContext = createContext<LoadingContextType>(initialState);

export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};

export const LoadingProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState<string>("");

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const startLoadingWithUrl = (urlName: string) => {
    setURL(urlName);
    setLoading(true);
  };

  const value: LoadingContextType = {
    loading,
    startLoading,
    startLoadingWithUrl,
    stopLoading,
  };

  return (
    <LoadingContext.Provider value={value}>
      {loading && (
        <div className="loaderMain">
          {url ? <div className="debugURL">API URL: {url}</div> : ""}

          <div className="loader"></div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};
