import { useTranslation } from "react-i18next";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { OrderModel } from "../../../../models/order/order";
import {
  TypeBackendDropDown,
  TypeAllDropDownTables,
} from "../../../../types/BackendDropDown";

export interface IFieldsParam {
  dropdown: TypeAllDropDownTables;
  state?: OrderModel;
}

type dropDownFilter = (params: IFieldsParam) => Array<TypeBackendDropDown>;

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const OrderMain1EN: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Order Number",
      tag: "input",
      placeholder: "Type Order Number",
      disabled: true,
    },

    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: "Vendor",
      tag: "select",
      placeholder: "Vendor",
      options: VendorDD({ dropdown, state }),
      required: true,
    },
    {
      id: "cardCategoryId",
      name: "cardCategoryId",
      type: "text",
      label: "Card Category Id",
      tag: "select",
      placeholder: "Card Category",
      required: true,
      options: CategoryDD({ dropdown, state }),
    },
    {
      id: "cardSubCategoryId",
      name: "cardSubCategoryId",
      type: "text",
      label: "Card Sub Category Id",
      tag: "select",
      placeholder: "Card Sub Category",
      options: SubCategoryDD({ dropdown, state }),
      required: true,
    },
    {
      id: "numberCards",
      name: "numberCards",
      type: "number",
      label: "Number Cards",
      placeholder: "Number of Cards",
      tag: "input",
      required: true,
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "Customer Id",
      tag: "select",
      placeholder: "CustomerId",
      options: dropdown?.customers,
      required: true,
    },
  ];
};

export const OrderMain1AR: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "رقم الأمر",
      tag: "input",
      placeholder: "Type Order Number",
      disabled: true,
    },

    {
      id: "vendorId",
      name: "vendorId",
      type: "text",
      label: "بائع",
      tag: "select",
      placeholder: "Vendor",
      options: VendorDD({ dropdown, state }),
      required: true,
    },
    {
      id: "cardCategoryId",
      name: "cardCategoryId",
      type: "text",
      label: "معرف فئة البطاقة",
      tag: "select",
      placeholder: "Card Category",
      required: true,
      options: CategoryDD({ dropdown, state }),
    },
    {
      id: "cardSubCategoryId",
      name: "cardSubCategoryId",
      type: "text",
      label: "معرف الفئة الفرعية للبطاقة",
      tag: "select",
      placeholder: "Card Sub Category",
      options: SubCategoryDD({ dropdown, state }),
      required: true,
    },
    {
      id: "numberCards",
      name: "numberCards",
      type: "number",
      label: "بطاقات الأرقام",
      placeholder: "Number of Cards",
      tag: "input",
      required: true,
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "هوية الزبون",
      tag: "select",
      placeholder: "CustomerId",
      options: dropdown?.customers,
      required: true,
    },
  ];
};

export const OrderMain2EN: displayFieldsFunction = (data) => {
  return [
    {
      id: "totalAmount",
      name: "totalAmount",
      type: "number",
      label: "Total Amount",
      placeholder: "Total Amount",
      tag: "input",
      disabled: true,
    },
    {
      id: "nominalCardPrice",
      name: "nominalCardPrice",
      type: "number",
      label: "Nominal Card Price",
      placeholder: "Nominal Card Price",
      tag: "input",
      disabled: true,
    },
    {
      id: "cardPrice",
      name: "cardPrice",
      type: "number",
      label: "Card Price",
      placeholder: "CardPrice",
      tag: "input",
      disabled: true,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "Currency",
      placeholder: "Currency",
      tag: "input",
      disabled: true,
    },
  ];
};

export const OrderMain2AR: displayFieldsFunction = (data) => {
  return [
    {
      id: "totalAmount",
      name: "totalAmount",
      type: "number",
      label: "المبلغ الإجمالي",
      placeholder: "Total Amount",
      tag: "input",
      disabled: true,
    },
    {
      id: "nominalCardPrice",
      name: "nominalCardPrice",
      type: "number",
      label: "سعر البطاقة الاسمية",
      placeholder: "Nominal Card Price",
      tag: "input",
      disabled: true,
    },
    {
      id: "cardPrice",
      name: "cardPrice",
      type: "number",
      label: "سعر البطاقة",
      placeholder: "CardPrice",
      tag: "input",
      disabled: true,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "عملة",
      placeholder: "Currency",
      tag: "input",
      disabled: true,
    },
  ];
};

export const VendorDD: dropDownFilter = ({ dropdown, state }) => {
  debugger;
  return dropdown?.vendors;
};

export const CategoryDD: dropDownFilter = ({ dropdown, state }) => {
  debugger;
  if (state?.vendorId) {
    return dropdown?.categories.filter((x) => {
      return x.filter1 == state?.vendorId;
    });
  }
  return dropdown?.categories;
};

export const SubCategoryDD: dropDownFilter = ({ dropdown, state }) => {
  debugger;
  if (state?.cardCategoryId) {
    return dropdown?.subCategories.filter((x) => {
      return x.filter1 == state?.cardCategoryId;
    });
  }
  return dropdown?.subCategories;
};
