import React, { FC, useContext } from "react";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { CustomerContext } from "../Common/CustomerContext";
import { CustomerMainEN, CustomerMainAR } from "./Fields";
import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";

const MainData: FC = () => {
  const contextData = useContext(CustomerContext);
  const { i18n } = useTranslation();
  const userInfo = useAppSelector((state) => state.userInfo);

  const fieldsToRender =
    i18n.language === "ar"
      ? CustomerMainAR({
          dropdown: contextData?.dropDowns,
          state: contextData?.customerData,
          disabled: userInfo.userTypeId === "SuperAdmin" ? false : true,
          disabled2: contextData?.operation === "Create" ? true : false
        })
      : CustomerMainEN({
          dropdown: contextData?.dropDowns,
          state: contextData?.customerData,
          disabled: userInfo.userTypeId === "SuperAdmin" ? false : true,
          disabled2: contextData?.operation === "Create" ? true : false
        });

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.customerData },
      onChange: contextData?.changeHandler,
      errorMessage: contextData?.fieldValidation?.find((x) => x.id == field.id)
        ?.message,
    };

    switch (contextData?.operation) {
      case "Display":
        //When display lock all fields
        newFieldObject.disabled = true;
        break;
      case "Create":
        break;
      case "Change":
        if (newFieldObject.keyField) {
          newFieldObject.disabled = true;
        }
        break;
      default:
    }
    if (contextData?.operation === "Create") {
      if (newFieldObject.id === "updatePassword") {
        newFieldObject.disabled = true;
      }
    }
    return newFieldObject;
  };
  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={fieldsToRender.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default MainData;
