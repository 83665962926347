import {
  useQuery,
  useLazyQuery,
  gql,
  QueryResult,
  LazyQueryExecFunction,
  ApolloError,
} from "@apollo/client";
import { IuseReturn } from "./IUseReturn";

// export interface IuseOrderReportReturn
// {
//     execMe: LazyQueryExecFunction<any, any>
//     data:any
//     loading: boolean
//     error: ApolloError | undefined
// }
// export const useOrderReport = (selection:any):IuseOrderReportReturn =>  {

//     // let op: string = "eq";
//     // let whereString: {} = {};

//     // let x: {} = {};

//     // if (selection.Id) {
//     //   whereString = { ...whereString, id: { [op]: selection.Id } };
//     // }

//     // // if (selection.Id) {
//     // //   whereString = { ...whereString, id: { [op]: selection.Id } };
//     // // }

//     // if (selection.VendorId) {
//     //   whereString = { ...whereString, vendorId: { [op]: selection.VendorId } };
//     // }

//     // if (selection.CardCategoryId) {
//     //   whereString = {
//     //     ...whereString,
//     //     cardCategoryId: { [op]: selection.CardCategoryId },
//     //   };
//     // }

//     // let variableString: any = {
//     //   whereString,
//     // };

//     const dynamicQuery = gql`
//     query getAll($whereString: OrderHeaderFilterInput!) {
//       orders(where: $whereString) {
//         id
//         tenantId
//         vendorId
//         customerId
//         orderDT
//         cardCategoryId
//         cardSubCategoryId
//         numberCards
//         nominalCardPrice
//         cardPrice
//         totalAmount
//         currencyId
//         items{itemNumber}

//       }
//     }
//   `;
//     // const {error, data, loading, refetch} = useQuery(dynamicQuery, {
//     //     variables: variableString,
//     //   },);

//     //Removed the variables from query and being passed via execMe function
//     //   const [execMe, { loading, error, data }]= useLazyQuery(dynamicQuery, {
//     //   variables: variableString,
//     // })
//       const [execMe, { loading, error, data }]= useLazyQuery(dynamicQuery)

// return {execMe, data, loading, error};

// }

export const useOrder = (): IuseReturn => {
  const useOrderHeaderReport = gql`
    query GetOrders($whereString: OrderHeaderFilterInput!) {
      orders(where: $whereString) {
        id
        tenantId
        vendorId
        customerId
        orderDT
        cardCategoryId
        cardSubCategoryId
        numberCards
        nominalCardPrice
        cardPrice
        totalAmount
        currencyId
        items {
          itemNumber
        }
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(useOrderHeaderReport, {
    skip: true, // Skip the initial execution
  });

  return { refetch, data, loading, error };
};
