import React, { FC, useState, useEffect, useRef } from "react";
import { IFields } from "../../../commonComponents/rendering/mUIReducer/IFields";
import { Reducer } from "../../../commonComponents/rendering/mUIReducer/Reducer";
import { ReportFields } from "./Fields";
import { ICustomerWorkbenchState } from "./Interfaces";
import Card from "../../../commonComponents/cards/fieldsCard/Card/Card";
import Button from "react-bootstrap/Button";
import { useCustomerReport } from "../../../Hooks/UseCustomerReport";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenSquare,
  faBinoculars,
  faMoneyCheckDollar,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { Container, Form, Modal } from "react-bootstrap";
import { useMessageI18n } from "../../../Hooks/useMessageI18n";
import { useTranslation } from "react-i18next";
import useNavigateSearch from "../../../Hooks/useNavigationSearch";
import { BaseURLs } from "../../../commonComponents/GlobalFiles/URLs";
import { UseFetch } from "../../../Hooks/UseFetchNew";
import { ChangeCustomerBalance } from "../../../models/MasterData/ChangeCustomerBalance";
const CustomerWorkbench: FC = () => {
  const { t } = useTranslation();
  var GridFields: Array<GridColDef> = [
    {
      field: "id",
      headerName: t("customer_id") ?? "",
      width: 200,
    },

    {
      field: "firstName",
      headerName: t("first_name") ?? "",
      width: 200,
    },
    {
      field: "lastName",
      headerName: t("last_name") ?? "",
      width: 200,
    },
    {
      field: "balance",
      headerName: t("balance") ?? "",
      width: 200,
    },
  ];
  let navigate = useNavigate();
  const useMessagesI18n = useMessageI18n();
  const [selectedId, setSelectedId] = useState<GridSelectionModel>([]);
  const [changeCustomerBalance, setChangeCustomerBalance] =
    useState<ChangeCustomerBalance>();
  const [selection, setSelection] = useState<ICustomerWorkbenchState>({
    Id: "",
    FirstName: "",
    Lastname: "",
  });
  const { refetch } = useCustomerReport();
  const userInfo = useAppSelector((state) => state.userInfo);
  const dropDowns = useAppSelector((state) => state.dropDowns);
  const navigateSearch = useNavigateSearch();
  const selectionString = useRef<any>();
  const [gridData, setGridData] = useState<any>();
  const [showAddToBalanceModal, setShowAddToBalanceModal] = useState(false);
  const [showUpdateBalanceModal, setShowUpdateBalanceModal] = useState(false);
  const [balanceToAdd, setBalanceToAdd] = useState(0);

  useEffect(() => {
    //Customer can not display customer(s), this an admin only function
    if (userInfo.userTypeId == "Customer") {
      navigate("/Home");
    }
  }, []);

  const addToBalance = UseFetch({
    url: `${BaseURLs?.FIDOC}AddCustomerBalance`,
    method: "POST",
    loading: true,
    noToasterMessages: true,
  });

  const changeBalance = UseFetch({
    url: `${BaseURLs?.FIDOC}UpdateCustomerBalance`,
    method: "POST",
    loading: true,
    noToasterMessages: true,
  });

  const fieldsToRender = ReportFields({
    dropdown: dropDowns,
    state: selection,
  });
  function changeHandler(e: any) {
    const { name, value } = e.target;
    setSelection((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      onChange: changeHandler,
      dataSource: { ...selection },
    };
    return newFieldObject;
  };

  const RunReport = async () => {
    let whereString: {} = {};
    let andWhereString: {} = {};

    if (selection?.Id) {
      andWhereString = { Id: { eq: selection.Id } };
    }

    if (selection?.FirstName) {
      andWhereString = {
        ...andWhereString,
        FirstName: { eq: selection.FirstName },
      };
    }

    if (selection?.Lastname) {
      andWhereString = {
        ...andWhereString,
        Lastname: { eq: selection.Lastname },
      };
    }

    whereString = { and: [andWhereString] };

    selectionString.current = whereString;

    let variableString: any = {
      whereString,
    };
    const { data } = await refetch(variableString);
    if (data?.customers.length > 0) {
      const newArray = data?.customers.map((item: any) => {
        const { __typename, ...rest } = item;
        return rest;
      });
      setGridData(newArray);
    } else {
      useMessagesI18n.addMessage("7", "E");
      return;
    }
  };

  useEffect(() => {
    setChangeCustomerBalance({
      customerId: selectedId.toString(),
      amount: balanceToAdd,
    });
  }, [selectedId, balanceToAdd]);

  const toggleAddToBalanceModal = () => {
    if (!selectedId[0]) {
      useMessagesI18n.addMessage("4", "E");
      return;
    }
    setShowAddToBalanceModal(!showAddToBalanceModal);
  };

  const handleAddToBalanceChange = () => {
    addToBalance.fetchData({
      callingData: {
        ...changeCustomerBalance,
      },
      onSuccess: ({ data }) => {
        setBalanceToAdd(0);
      },
      onError: (res) => {},
    });

    toggleAddToBalanceModal();
  };

  const toggleUpdateBalanceModal = () => {
    if (!selectedId[0]) {
      useMessagesI18n.addMessage("4", "E");
      return;
    }
    setShowUpdateBalanceModal(!showUpdateBalanceModal);
  };

  const handleBalanceChange = () => {
    changeBalance.fetchData({
      callingData: {
        ...changeCustomerBalance,
      },
      onSuccess: ({ data }) => {
        setBalanceToAdd(0);
      },
      onError: (res) => {},
    });

    toggleUpdateBalanceModal();
  };

  function Create() {
    navigateSearch("/Customer", { operation: "Create" });
  }

  function Change() {
    if (!selectedId[0]) {
      useMessagesI18n.addMessage("4", "E");
      return;
    }
    navigateSearch("/Customer", { operation: "Change", id: selectedId });
  }
  function Display() {
    if (!selectedId[0]) {
      useMessagesI18n.addMessage("4", "E");
      return;
    }
    navigateSearch("/Customer", { operation: "Display", id: selectedId });
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          gap: "20px",
        }}
      >
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={Create}
        >
          {t("create_customer")}
        </Button>
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={RunReport}
        >
          {t("searchCustomer")}
        </Button>
      </div>

      <Card
        cardHeaderProps={{
          headerLabel: "Order Workbench",
        }}
        cardBody={fieldsToRender.map((field, index) => (
          <Reducer key={index} {...mergedFieldData(field)} />
        ))}
        style={{ width: "40%" }}
      />
      <Button
        className="btn-danger"
        onClick={() => {
          Display();
        }}
      >
        <FontAwesomeIcon icon={faBinoculars} />
        {t("display")}
      </Button>
      <Button
        className="mx-2"
        onClick={() => {
          Change();
        }}
      >
        <FontAwesomeIcon icon={faPenSquare} />
        {t("change")}
      </Button>
      <Button className="mx-2" onClick={toggleAddToBalanceModal}>
        <FontAwesomeIcon icon={faCircleDollarToSlot} />
        {t("add_to_balance")}
      </Button>
      <Modal show={showAddToBalanceModal} onHide={toggleAddToBalanceModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("add_to_balance")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId={t("balance_to_add") ?? "Balance To Add"}>
              <Form.Label>{t("enter_amount")}</Form.Label>
              <Form.Control
                type="number"
                value={balanceToAdd}
                onChange={(e) => setBalanceToAdd(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleAddToBalanceModal}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={handleAddToBalanceChange}>
            {t("update_balance")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Button className="mx-2" onClick={toggleUpdateBalanceModal}>
        <FontAwesomeIcon icon={faMoneyCheckDollar} />
        {t("change_balance")}
      </Button>
      <Modal show={showUpdateBalanceModal} onHide={toggleUpdateBalanceModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("change_balance")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId={t("balance_to_add") ?? "Balance To Add"}>
              <Form.Label>{t("enter_amount")}</Form.Label>
              <Form.Control
                type="number"
                value={balanceToAdd}
                onChange={(e) => setBalanceToAdd(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleUpdateBalanceModal}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={handleBalanceChange}>
            {t("change_balance")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={gridData || []}
          columns={GridFields}
          pageSize={10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(selection) => {
            const selectedRowData = gridData.filter(
              (row: any) => row.id == selection
            );
            setSelectedId(selection);
          }}
        />
      </Box>
    </Container>
  );
};

export default CustomerWorkbench;
