import React, { FC } from "react";
import Card from "../../commonComponents/cards/navigationCard/Card";
import { useAppSelector } from "../../redux/hooks";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Home: FC = () => {
  const userData = useAppSelector((state) => state.userInfo);
  const { t } = useTranslation();
  const CardsArray = [
    {
      title: t("settingsMaster"),
      text: "",
      link: "/MasterSettings",
      roles: ["SuperAdmin"],
      systemConfig: true,
    },
    {
      title: t("settings"),
      text: "",
      link: "/Settings",
      roles: ["Admin", "SuperAdmin"],
      systemConfig: true,
    },
    {
      title: t("master_data"),
      text: "",
      link: "/MasterDataHome",
      roles: ["Admin", "SuperAdmin"],
    },
    {
      title: t("business_processes"),
      text: "",
      link: "/BusinessProcesses",
      roles: ["Admin", "SuperAdmin", "Customer"],
    },
    {
      title: t("user_settings"),
      text: "",
      link: "/UserSettings",
      roles: ["Admin", "SuperAdmin", "Customer"],
    },
    {
      title: t("reports"),
      text: "",
      link: "/ReportsHome",
      roles: ["Admin", "SuperAdmin"],
    },
    // {
    //   title: t("basic_system"),
    //   text: "",
    //   link: "/BasicSystem",
    //   roles: ["SuperAdmin"],
    // },
  ];

  return (
    <div className="overviewCardContainer">
      <Container>
        <Row style={{ marginTop: 40 }}>
          {CardsArray.map((record, i) => {
            var commonRole: boolean = false;
            userData.roles?.forEach((role) => {
              if (record.roles.includes(role)) {
                commonRole = true;
                return;
              }
            });
            if (commonRole) {
              return (
                <Card
                  title={record.title}
                  cardBody={record.text}
                  link={record.link}
                  key={i}
                />
              );
            }
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Home;
