import React, { FC, useEffect } from "react";
import { Route } from "react-router-dom";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "../login/Login";
import Home from "../homePages/Home";
import ProtectedRoutes from "./ProtectedRoutes";
import OrderWorkbench from "../../BusinessProcesses/Order/OrderWorkbench/Main";
import OrderMain from "../../BusinessProcesses/Order/ManageOrder/OrderMain";
import OrderOverview from "../../BusinessProcesses/Order/ManageOrder/Overview/OrderOverview";
import AppHeader from "../../commonComponents/headers/AppHeader";
import Reports from "../../Reports/Reports";
import AvailableCards from "../../Reports/AvailableCards/AvailableCards";
import SettingTree from "../../BusinessProcesses/Settings/SettingsTree";
import MasterData from "../../MasterData/MasterDataHome";
import CustomerWorkbench from "../../MasterData/Customer/CustomerWorkbench/CustomerWorkbench";
import CardMain from "../../MasterData/Card/ManageCard/CardMain";
import UserSettings from "../../User/UserSettings";
import { BaseURLs } from "../../commonComponents/GlobalFiles/URLs";
import { newUserData } from "../../redux/features/UserSlice";
import { useAppDispatch } from "../../redux/hooks";
import { newDropDown } from "../../redux/features/DropDownSlice";
import CardWorkbench from "../../MasterData/Card/CardWorkbench/CardWorkbench";
import DirectChargeOrderMain from "../../BusinessProcesses/DirectChargeOrder/ManageOrder/DirectChargeOrderMain";
import DirectChargeOrderWorkbench from "../../BusinessProcesses/DirectChargeOrder/Workbench/Main";
import BusinessProcesses from "../../BusinessProcesses/BusinessProcesses";
import CustomerMain from "../../MasterData/Customer/ManageCustomer/CustomerMain";
import { IFetchReturn, useFetch } from "../../Hooks/UseFetch";
import { UseFetch } from "../../Hooks/UseFetchNew";
import MasterSettingTree from "../../BusinessProcesses/Settings/MasterSettings/MasterSettingsTree";
import CustomerFinancials from "../../Reports/CustomerFinancials/CustomerFinancials";

const Navigation: FC = () => {
  const dispatch = useAppDispatch();
  const getDropDowns: IFetchReturn = useFetch(
    `${BaseURLs?.AllDropDowns}`,
    "GET",
    true
  );

  const getUser = UseFetch({
    url: `${BaseURLs?.User}GetUser`,
    method: "GET",
    loading: true,
    noToasterMessages: true,
  });
  function getAllDropDowns() {
    getDropDowns.fetchData({}, (res) => {
      dispatch(newDropDown(res));
    });
  }

  function getuserData() {
    getUser.fetchData({
      params: { id: localStorage.getItem("rc_id") },
      onSuccess: ({ data }) => {
        dispatch(newUserData(data));
      },
      onError: (res) => {},
    });
  }

  useEffect(() => {
    if (localStorage.getItem("rc_token")) {
      getAllDropDowns();
      getuserData();
    }
  }, []);

  return (
    <Router>
      <AppHeader />
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/BusinessProcesses" element={<BusinessProcesses />} />
          <Route path="/Order" element={<OrderMain />} />
          <Route path="/Order/" element={<OrderMain />} />
          <Route path="/OrderWorkbench" element={<OrderWorkbench />} />
          <Route path="/OrderOverview" element={<OrderOverview />} />
          <Route path="/ReportsHome" element={<Reports />} />
          <Route path="/AvailableCards" element={<AvailableCards />} />
          <Route path="/MasterDataHome" element={<MasterData />} />
          <Route path="/Settings" element={<SettingTree />} />
          <Route path="/MasterSettings" element={<MasterSettingTree />} />
          <Route path="/CardWorkbench" element={<CardWorkbench />} />
          <Route path="/CustomerWorkbench" element={<CustomerWorkbench />} />
          <Route path="/Customer" element={<CustomerMain />} />
          <Route path="/CustomerFinancials" element={<CustomerFinancials />} />
          <Route path="/Card" element={<CardMain />} />

          <Route path="/UserSettings" element={<UserSettings />} />
          <Route
            path="/DirectChargeOrderWorkbench"
            element={<DirectChargeOrderWorkbench />}
          />
          <Route
            path="/DirectChargeOrder/"
            element={<DirectChargeOrderMain />}
          />
        </Route>

        <Route path="/Login" element={<Login />} />

        <Route path="*" element={<h1> page not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default Navigation;
