import { useTranslation } from "react-i18next";
import { simpleGridFields } from "../../../../commonComponents/grids/simpleGrid/interfaces";

export const OrderItemEN: simpleGridFields = () => {
  return [
    {
      id: "itemNumber",
      label: "Item",
    },
    {
      id: "cardId",
      label: "Card Id",
    },
    {
      id: "cardNumber",
      label: "Card Number",
    },
    {
      id: "cardValue",
      label: "Card Value",
    },
  ];
};

export const OrderItemAR: simpleGridFields = () => {
  return [
    {
      id: "itemNumber",
      label: "غرض",
    },
    {
      id: "cardId",
      label: "بطاقة الهوية",
    },
    {
      id: "cardNumber",
      label: "رقم البطاقة",
    },
    {
      id: "cardValue",
      label: "قيمة البطاقة",
    },
  ];
};
