import { TypeFieldValidations } from "./../../../../types/TypeFieldValidation";
import { createContext } from "react";
import { CardModel } from "../../../../models/MasterData/Card";
import { TypeAllDropDownTables } from "../../../../types/BackendDropDown";

export interface ICardContext {
  operation?: string;
  cardId?: string;
  cardData?: CardModel | undefined;
  setCardData?: React.Dispatch<React.SetStateAction<CardModel | undefined>>;
  changeHandler?: (e: any) => void;
  dropDowns: TypeAllDropDownTables;
  fieldValidation?: TypeFieldValidations;
}

export const CardContext = createContext<ICardContext | null>(null);
