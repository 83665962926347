import React, { FC, useState, useEffect, useContext, ReactNode } from "react";
import { DirectChargeOrderContext } from "./DirectChargeOrderContext";
import { useTranslation } from "react-i18next";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const Header: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const contextData = useContext(DirectChargeOrderContext);
  var orderInfo: string;
  switch (contextData?.operation) {
    case "Display":
      orderInfo = `${t("display")} ${t("direct_charge_order")}: ${
        contextData?.orderId
      }`;
      break;
    case "Change":
      orderInfo = `${t("change")} ${t("direct_charge_order")}: ${
        contextData?.orderId
      }`;
      break;
    case "Create":
      orderInfo = `${t("create")} ${t("direct_charge_order")}`;
      break;
    default:
      orderInfo = t("direct_charge_order");
      break;
  }
  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#E2DFDF" }}>
        <div className="col-6" style={{ fontSize: "20px" }}>
          {orderInfo}
        </div>
        <div className="col-6" style={{ fontSize: "20px" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
