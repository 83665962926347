import { useState } from "react";
import { issueMessage } from "../commonComponents/messages/issueMessage";
import { useAppSelector } from "../redux/hooks";
import {MessageFileType, English} from "./../commonComponents/messages/English";
import {Arabic} from "./../commonComponents/messages/Arabic";
export interface IUseMessageReturn
{
   error:string[];
   setError:React.Dispatch<React.SetStateAction<Array<string>>>;

   success:string[];
   setSuccess:React.Dispatch<React.SetStateAction<Array<string>>>;
   
   warning:string[];
   setWarning:React.Dispatch<React.SetStateAction<Array<string>>>;

   addMessage:AddMessageType
}

type AddMessageType = (messageNumber:string,  messageType:string, var1?:string, var2?:string, var3?:string, var4?:string) => void


export const useMessage = ():IUseMessageReturn  => {
    const value = useAppSelector((state) => state.userInfo);
    const [error, setError] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<Array<string>>([]);
    const [warning, setWarning] = useState<Array<string>>([]);


const addMessage: AddMessageType = (
        messageNumber,
        messageType,
        var1,
        var2,
        var3,
        var4,        
      ) => {
let language:string = "";
let messageInfo: string = "";
let totalMessage: string = "";
var allMessages:MessageFileType;
var messageObject:{id:string, message:string} | undefined;


if (!value.preferredLanguage){language = "EN"}else{language = value.preferredLanguage}

switch (language) {
    case "EN":
    allMessages = English();
     messageObject = allMessages.find(({ id }) => id == messageNumber);

      if (messageObject) {
        let Messagefile = messageObject.message;
        let newMessage = Messagefile.replace("#1", var1 || "")
          .replace("#2", var2 || "")
          .replace("#3", var3 || "")
          .replace("#4", var4 || "");

        messageInfo = `FR-${messageObject.id}`;
        totalMessage = `${messageInfo}: ${newMessage}`;
      }    break;
    case "AR":
     allMessages = Arabic();
     messageObject = allMessages.find(({ id }) => id == messageNumber);

      if (messageObject) {
        let Messagefile = messageObject.message;
        let newMessage = Messagefile.replace("#1", var1 || "")
          .replace("#2", var2 || "")
          .replace("#3", var3 || "")
          .replace("#4", var4 || "");

        messageInfo = `FR-${messageObject.id}`;
        totalMessage = `${messageInfo}: ${newMessage}`;
      }    break;
      // code block
    default:
    // code block
  }
if(!messageObject){   setError((c)=>{ return [...c, "Message Not Found"]})}

  switch (messageType) {
    case "E":
        setError((c)=>{ return [...c, totalMessage]});
        break;

    case "S":
        setSuccess((c)=>{ return [...c, totalMessage]});
        break;
        case "W":
       setWarning((c)=>{ return [...c, totalMessage]});
        break;
        default:
        setError((c)=>{ return [...c, totalMessage]});
        break;
        }
      }

    return{error, setError, success, setSuccess, warning, setWarning, addMessage}

  };