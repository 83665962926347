import React, { FC } from "react";
import { IFields } from "./IFields";
// import { DateTimePicker, Select } from "react-rainbow-components";
import moment from "moment";
import ImageUploading, { ImageListType } from "react-images-uploading";
// import Select from "react-select";
import "./Reducer.scss";

import {
  TextField,
  BaseTextFieldProps,
  OutlinedTextFieldProps,
  colors,
  MenuItem,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export const Reducer = (props: any) => {
  switch (props.tag) {
    case "input":
      return <TextInput {...props} />;
    case "select":
      return <SelectInput {...props} />;
    case "DateTime":
      return <DateTimeInput {...props} />;
    case "ImageUpload":
      return <ImageUpload {...props} />;
    case "Checkbox":
      return <Checkbox {...props} />;
    default:
      return <TextInput {...props} />;
  }
};

interface ITextInput extends OutlinedTextFieldProps {
  errormessage?: string;
}

const TextInput: FC<ITextInput> = (props) => {
  return (
    <>
      <TextField
        size="medium"
        {...props}
        className="w-100 mb-4"
        variant="outlined"
      />
      {props.errormessage && (
        <p style={{ marginTop: -15, marginBottom: 15, color: "red" }}>
          {props.errormessage}
        </p>
      )}
    </>
  );
};

const SelectInput: FC<IFields> = (props) => {
  const { options } = props;
  return (
    <>
      <TextField {...props} className="w-100 mb-4" select variant="outlined">
        {options?.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {props.errormessage && (
        <p style={{ marginTop: -15, marginBottom: 15, color: "red" }}>
          {props.errormessage}
        </p>
      )}
    </>
  );
};
const DateTimeInput: FC<IFields> = (props) => {
  const { t } = useTranslation();
  const { value, label, disabled, onChange } = props;
  const { setFieldValue } = useFormikContext() ?? {};
  // const [field] = useField(props.name);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          className="w-100 mb-4"
          renderInput={(props) => <TextField {...props} />}
          label={t("date_time_picker")}
          value={value}
          onChange={(val) => {
            setFieldValue(props.name, val);
          }}
        />
      </LocalizationProvider>
      {props.errormessage && (
        <p style={{ marginTop: -15, marginBottom: 15, color: "red" }}>
          {props.errormessage}
        </p>
      )}
    </>
  );
};

const ImageUpload: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  itemKey,
}) => {
  const { t } = useTranslation();
  function getValue() {
    if (dataSource && dataSource[id]) {
      return dataSource[id];
    }
    return "";
  }

  const onChangeImage = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    let imageData: { target: {} } = { target: {} };
    imageData.target = { name: id, value: imageList };
    onChange(imageData);
  };

  return (
    <ImageUploading
      multiple={false}
      value={getValue()}
      onChange={onChangeImage}
      maxNumber={1}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div>
          <button
            style={isDragging ? { color: "red" } : undefined}
            onClick={onImageUpload}
            {...dragProps}
          >
            {t("click_or_drop_here")}
          </button>
          &nbsp;
          <button onClick={onImageRemoveAll}>{t("remove_all_images")}</button>
          {imageList.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image.dataURL} alt="" width="100" />
              <div>
                <button onClick={() => onImageUpdate(index)}>Update</button>
                <button onClick={() => onImageRemove(index)}>Remove</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
};
const Checkbox: FC<IFields> = ({
  onChange = () => {},
  dataSource = {},
  id,
  value,
  label,
  type,
  name,
  disabled,
  noLable,
  length,
  itemKey,
  required,
}) => {
  return (
    <div className="form-check">
      {!noLable ? (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      ) : null}

      <input
        style={{ height: "20px", width: "20px" }}
        className="form-check-input"
        checked={
          dataSource?.[id] === "true" ||
          dataSource?.[id] === true ||
          dataSource?.[id] === "True"
        }
        value={dataSource?.[id]}
        onChange={onChange}
        data-type={type}
        type={type}
        data-item={dataSource?.["itemNumber"]}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};
