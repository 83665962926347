import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { MessageContext } from "./../admin/providers/MessageProvider";
type AddMessageType = (
  messageNumber: string,
  messageType: string,
  var1?: string,
  var2?: string,
  var3?: string,
  var4?: string
) => void;

export interface IUseMessageReturn {
  error: string[];
  setError: React.Dispatch<React.SetStateAction<Array<string>>>;

  success: string[];
  setSuccess: React.Dispatch<React.SetStateAction<Array<string>>>;

  warning: string[];
  setWarning: React.Dispatch<React.SetStateAction<Array<string>>>;

  addMessage: AddMessageType;
}

export const useMessageI18n = (): IUseMessageReturn => {
  const [error, setError] = useState<Array<string>>([]);
  const [success, setSuccess] = useState<Array<string>>([]);
  const [warning, setWarning] = useState<Array<string>>([]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const messageContext = useContext(MessageContext);
  let totalMessage: string = "";

  const addMessage: AddMessageType = (
    messageNumber,
    messageType,
    var1,
    var2,
    var3,
    var4
  ) => {
    totalMessage = t(`messages.${messageNumber}.message`, {
      var1: "User Name",
    }).toString();

    switch (messageType) {
      case "E":
        messageContext?.setError((current) => {
          return [...current, totalMessage];
        });
        setError((c) => {
          return [...c, totalMessage];
        });
        break;
      case "S":
        messageContext?.setSuccess((current) => {
          return [...current, totalMessage];
        });
        setSuccess((c) => {
          return [...c, totalMessage];
        });
        break;
      case "W":
        messageContext?.setWarning((current) => {
          return [...current, totalMessage];
        });
        setWarning((c) => {
          return [...c, totalMessage];
        });
        break;
      default:
        setError((c) => {
          return [...c, totalMessage];
        });
        break;
    }
  };

  return {
    error,
    setError,
    success,
    setSuccess,
    warning,
    setWarning,
    addMessage,
  };
};
