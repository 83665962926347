import { useTranslation } from "react-i18next";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { OrderModel } from "../../../../models/order/order";

export interface IFieldsParam {
  //dropdown: {categories: [{value:string, label:string, filter1:string, filter2:string}],currencies: [{value:string, label:string, filter1:string, filter2:string}], customers:[{value:string, label:string, filter1:string, filter2:string}], fiDocTypes:[{value:string, label:string, filter1:string, filter2:string}], subCategories:[{value:string, label:string, filter1:string, filter2:string}], tenants:[{value:string, label:string, filter1:string, filter2:string}], userTypes:[{value:string, label:string, filter1:string, filter2:string}],vendorTypes:[{value:string, label:string, filter1:string, filter2:string}], vendors:[{value:string, label:string, filter1:string, filter2:string}] };
  dropdown?: any;
  state?: OrderModel;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const OrderAdmin1EN: displayFieldsFunction = (data) => {
  return [
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const OrderAdmin1AR: displayFieldsFunction = (data) => {
  return [
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export type dropDownFilter = (
  params: IFieldsParam
) => [{ value: string; label: string; filter1: string; filter2: string }];

export const VendorDD: dropDownFilter = (data) => {
  return data?.dropdown?.vendors;
};

export const CategoryDD: dropDownFilter = (data) => {
  if (data?.state?.vendorId) {
    let cc: [
      { value: string; label: string; filter1: string; filter2: string }
    ];

    cc = data?.dropdown?.categories;

    return cc.filter((x) => {
      return x.filter1 == data?.state?.vendorId;
    });
  }
  return data?.dropdown?.categories;
};

export const SubCategoryDD: dropDownFilter = (data) => {
  if (data?.state?.cardCategoryId) {
    let cc: [
      { value: string; label: string; filter1: string; filter2: string }
    ];

    cc = data?.dropdown.subCategories;

    return cc.filter((x) => {
      return x.filter1 == data?.state?.cardCategoryId;
    });
  }
  return data?.dropdown.subCategories;
};
