import { useTranslation } from "react-i18next";
import { IFields } from "../../../commonComponents/rendering/mUIReducer/IFields";
import { ICustomerWorkbenchState } from "./Interfaces";

export interface IFieldsParam {
  //dropdown: {categories: [{value:string, label:string, filter1:string, filter2:string}],currencies: [{value:string, label:string, filter1:string, filter2:string}], customers:[{value:string, label:string, filter1:string, filter2:string}], fiDocTypes:[{value:string, label:string, filter1:string, filter2:string}], subCategories:[{value:string, label:string, filter1:string, filter2:string}], tenants:[{value:string, label:string, filter1:string, filter2:string}], userTypes:[{value:string, label:string, filter1:string, filter2:string}],vendorTypes:[{value:string, label:string, filter1:string, filter2:string}], vendors:[{value:string, label:string, filter1:string, filter2:string}] };
  dropdown?: any;
  state?: ICustomerWorkbenchState;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const ReportFields: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "Id",
      name: "Id",
      type: "text",
      label: t("customer_number"),
      tag: "input",
      placeHolder: "Type Customer Number",
    },
    {
      id: "FirstName",
      name: "FirstName",
      type: "text",
      label: t("first_name"),
      tag: "input",
      placeHolder: "First Name",
    },
    {
      id: "LastName",
      name: "LastName",
      type: "text",
      label: t("last_name"),
      tag: "input",
      placeHolder: "Last Name",
    },
  ];
};
