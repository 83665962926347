import React, { FC, useContext } from "react";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";

import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { OrderArrayEN, OrderArrayAR } from "./Fields";
import { CardContext } from "../Common/CardContext";
import { useTranslation } from "react-i18next";

const OrderData: FC = () => {
  const { i18n } = useTranslation();
  const contextData = useContext(CardContext);

  const fieldsToRender1 =
    i18n.language === "ar"
      ? OrderArrayAR({
          dropdown: contextData?.dropDowns,
          state: contextData?.cardData,
        })
      : OrderArrayEN({
          dropdown: contextData?.dropDowns,
          state: contextData?.cardData,
        });

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.cardData },
      onChange: contextData?.changeHandler,
    };
    return newFieldObject;
  };

  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={fieldsToRender1.map((field: any, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default OrderData;
