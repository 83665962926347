export type MessageFileType = Array<{id:string, message:string}>

export const English = ():MessageFileType => {

    return [ { "id": "1", "message": "Enter Order Number" },  
             { "id": "2", "message": "Detail View Fields are not Configured for table #1" },
             { "id": "3", "message": "Grid View Fields are not Configured for table #1" },
             { "id": "4", "message": "Must Select a Row Before Attepting to Proceed Futher" },
             { "id": "5", "message": "Can NOT Save, Data Incomplete" },       
            ]
}