import React, { FC, useState, useEffect, useContext, ReactNode } from "react";
import { CustomerAdminEN, CustomerAdminAR } from "./Fields";
import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { CustomerContext } from "../Common/CustomerContext";
import { useTranslation } from "react-i18next";

const MainData: FC = () => {
  const contextData = useContext(CustomerContext);
  const { i18n } = useTranslation();

  const fieldsToRender1 =
    i18n.language === "ar"
      ? CustomerAdminAR({
          dropdown: contextData?.dropDowns,
          state: contextData?.customerData,
        })
      : CustomerAdminEN({
          dropdown: contextData?.dropDowns,
          state: contextData?.customerData,
        });

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.customerData },
      onChange: contextData?.changeHandler,
    };
    return newFieldObject;
  };
  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={fieldsToRender1.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default MainData;
