import { CardModel } from "../../../../models/MasterData/Card";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { TypeAllDropDownTables } from "../../../../types/BackendDropDown";
import { useTranslation } from "react-i18next";

export interface IFieldsParam {
  dropdown?: TypeAllDropDownTables;
  state?: CardModel;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const CardAdminEN: displayFieldsFunction = (data) => {
  return [
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "inputMui",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "inputMui",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const CardAdminAR: displayFieldsFunction = (data) => {
  return [
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "inputMui",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "inputMui",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};
