import React, { FC, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Cards.css";
export interface ICard {
  title?: string;
  cardBody: string;
  icon?: string;
  link?: string;
}

const Card: FC<ICard> = ({ title, cardBody, icon, link }) => {
  let navigate = useNavigate();

  return (
    <Col
      xs={12}
      md={4}
      lg={3}
      className="containerOverviewCustomCard"
      onClick={() => navigate(`${link}`)}
    >
      <div className="overviewCustomCard">
        <h5>{title}</h5>
        <p>{cardBody}</p>
      </div>
    </Col>
  );
};

export default Card;
