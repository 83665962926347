import React, { FC, useState, useEffect, useContext } from "react";
import { OrderContext } from "../Common/OrderContext";
import {
  OrderMain1EN,
  OrderMain1AR,
  OrderMain2EN,
  OrderMain2AR,
} from "./Fields";
import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";

const MainData: FC = () => {
  const contextData = useContext(OrderContext);
  const userInfo = useAppSelector((state) => state.userInfo);
  const dropDowns = useAppSelector((state) => state.dropDowns);
  const [mainFields1, setMainFields1] = useState<Array<IFields>>([]);
  const [mainFields2, setMainFields2] = useState<Array<IFields>>([]);
  const { i18n } = useTranslation();
  useEffect(() => {
    debugger;
    if (i18n.language == "ar") {
      setMainFields1(
        OrderMain1AR({
          dropdown: dropDowns.data,
          state: contextData?.orderData,
        })
      );
      setMainFields2(
        OrderMain2AR({
          dropdown: dropDowns.data,
          state: contextData?.orderData,
        })
      );
    } else {
      setMainFields1(
        OrderMain1EN({
          dropdown: dropDowns.data,
          state: contextData?.orderData,
        })
      );
      setMainFields2(
        OrderMain2EN({
          dropdown: dropDowns.data,
          state: contextData?.orderData,
        })
      );
    }
  }, [dropDowns.data, contextData?.orderData]);
  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.orderData },
      onChange: contextData?.changeHandler,
    };
    //If the loggen-in user is a customer, we need to lock the customer id when  creating orders becuase customer
    //can not create orders for other customers
    if (userInfo.userTypeId == "Customer") {
      if (newFieldObject.id == "customerId") {
        newFieldObject.disabled = true;
      }
    }
    //In the change mode all fields should be grayed out (with the expection of notes which we do have now)
    if (contextData?.operation == "Change") {
      newFieldObject.disabled = true;
    }

    return newFieldObject;
  };

  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={mainFields1.map((field, index) => {
            return (
              <Reducer key={index.toString()} {...mergedFieldData(field)} />
            );
          })}
          style={{ width: "60%" }}
        />
      </div>
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={mainFields2.map((field, index) => {
            return (
              <Reducer key={index.toString()} {...mergedFieldData(field)} />
            );
          })}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default MainData;
