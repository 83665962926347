import React, { FC, ReactNode, createContext, useContext } from "react";
import MessageLog from "../../commonComponents/messageLog/MessageLog";
import { IUseMessageReturn, useMessageI18n } from "../../Hooks/useMessageI18n";
export const MessageContext = createContext<IUseMessageReturn | null>(null);

const MessageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const messageContextValue = useMessageI18n();

  return (
    <MessageContext.Provider value={messageContextValue}>
      {children}

      <MessageLog
        errorMessages={[...messageContextValue.error]}
        successMessages={[...messageContextValue.success]}
        warningMessages={[...messageContextValue.warning]}
        setError={messageContextValue.setError}
        setSuccess={messageContextValue.setSuccess}
        setWarning={messageContextValue.setWarning}
      />
    </MessageContext.Provider>
  );
};

export default MessageProvider;
