import React, { FC, useState, useEffect, useRef } from "react";
import { IFields } from "../../../commonComponents/rendering/originalReducer/IFields";
import { Reducer } from "../../../commonComponents/rendering/originalReducer/Reducer";
import { ReportFields } from "./Fields";
import { IOrderWorkbenchState } from "./Interfaces";
import Card from "./../../../commonComponents/cards/fieldsCard/Card/Card";
import Button from "react-bootstrap/Button";
import { useOrder } from "./../../../Hooks/UseOrderReport";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { GridFieldsEN, GridFieldsAR } from "./GridFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faBinoculars } from "@fortawesome/free-solid-svg-icons";
import { useMessage, IUseMessageReturn } from "./../../../Hooks/UseMessage";
import { useAppSelector } from "../../../redux/hooks";
import { Container } from "react-bootstrap";
import useNavigateSearch from "../../../Hooks/useNavigationSearch";
import { useTranslation } from "react-i18next";
import { useMessageI18n } from "../../../Hooks/useMessageI18n";

const OrderWorkbench: FC = () => {
  const navigateSearch = useNavigateSearch();
  const useMessages: IUseMessageReturn = useMessage();
  const { t, i18n } = useTranslation();
  const [gridData, setGridData] = useState<any>();

  const [selectedId, setSelectedId] = useState<GridSelectionModel>([]);
  const [selection, setSelection] = useState<IOrderWorkbenchState>({
    Id: "",
    OrderDT: new Date(),
    VendorId: "",
    CardCategoryId: "",
    CardSubCategoryId: "",
    CustomerId: "",
  });
  const { refetch } = useOrder();
  const selectionString = useRef<any>();
  const userInfo = useAppSelector((state) => state.userInfo);
  const dropDowns = useAppSelector((state) => state.dropDowns);
  const useMessagesI18n = useMessageI18n();

  useEffect(() => {
    //If the user type is a customer AND we are in the create then set the customer
    if (userInfo.userTypeId == "Customer") {
      setSelection({ CustomerId: userInfo.id });
    }
    //added dependecy "userInfo.userTypeId" in order to address F5 refresh for setting the customer(above)
  }, [userInfo.userTypeId]);

  const fieldsToRender = ReportFields({
    dropdown: dropDowns.data,
    state: selection,
  });
  function changeHandler(e: any) {
    const { name, value } = e.target;
    setSelection((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      onChange: changeHandler,
      dataSource: { ...selection },
    };

    //If the loggen-in user is a customer, we need to lock the customer id
    if (userInfo.userTypeId == "Customer") {
      if (newFieldObject.id == "CustomerId") {
        newFieldObject.disabled = true;
      }
    }
    return newFieldObject;
  };

  const RunReport = async () => {
    let whereString: {} = {};
    let andWhereString: {} = {};

    if (selection?.Id) {
      andWhereString = { id: { eq: selection.Id } };
    }

    if (selection?.VendorId) {
      andWhereString = { vendorId: { eq: selection.VendorId } };
    }

    if (selection?.CardCategoryId) {
      andWhereString = {
        ...andWhereString,
        cardCategoryId: { eq: selection.CardCategoryId },
      };
    }
    if (selection?.CardSubCategoryId) {
      andWhereString = {
        ...andWhereString,
        cardSubCategoryId: { eq: selection.CardSubCategoryId },
      };
    }
    // if (selection?.OrderDT) {
    //   andWhereString = {
    //     ...andWhereString,
    //     orderDT: { eq: selection.OrderDT },
    //   };
    // }

    if (selection?.CustomerId) {
      andWhereString = {
        ...andWhereString,
        customerId: { eq: selection.CustomerId },
      };
    }

    whereString = { and: [andWhereString] };

    selectionString.current = whereString;

    let variableString: any = {
      whereString,
    };
    setGridData([]);
    const { data } = await refetch(variableString);
    if (data?.orders.length > 0) {
      const newArray = data?.orders.map((item: any) => {
        const { __typename, ...rest } = item;
        return rest;
      });
      setGridData(newArray);
    } else {
      useMessagesI18n.addMessage("8", "E");
      return;
    }
  };

  function Create() {
    navigateSearch("/Order", { operation: "Create" });
  }

  function Change() {
    if (!selectedId[0]) {
      useMessages.addMessage("4", "E");
      return;
    }
    navigateSearch("/Order", { operation: "Change", id: selectedId });
  }
  function Display() {
    if (!selectedId[0]) {
      useMessages.addMessage("4", "E");
      return;
    }
    navigateSearch("/Order", { operation: "Display", id: selectedId });
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          gap: "20px",
        }}
      >
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={Create}
        >
          {t("create_order")}
        </Button>
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={RunReport}
        >
          {t("searchOrders")}
        </Button>
      </div>

      <Card
        cardHeaderProps={{
          headerLabel: "Order Workbench",
        }}
        cardBody={fieldsToRender.map((field, index) => (
          <Reducer key={index} {...mergedFieldData(field)} />
        ))}
        style={{ width: "40%" }}
      />
      <Button
        className="btn-danger"
        onClick={() => {
          Display();
        }}
      >
        <FontAwesomeIcon icon={faBinoculars} />
        {t("display")}
      </Button>
      <Button
        className="mx-2"
        onClick={() => {
          Change();
        }}
      >
        <FontAwesomeIcon icon={faPenSquare} />
        {t("change")}
      </Button>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={gridData || []}
          columns={i18n.language === "ar" ? GridFieldsAR : GridFieldsEN}
          pageSize={10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(selection) => {
            const selectedRowData = gridData.filter(
              (row: any) => row.id == selection
            );
            setSelectedId(selection);
          }}
        />
      </Box>
    </Container>
  );
};

export default OrderWorkbench;
