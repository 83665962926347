import { CustomerModel } from "../../../../models/MasterData/Customer";
import { createContext } from "react";
import { TypeFieldValidations } from "../../../../types/TypeFieldValidation";
import { TypeAllDropDownTables } from "../../../../types/BackendDropDown";
import { UserModel } from "../../../../models/User/User";

export interface ICustomerContext {
  operation?: string;
  customerId?: string;
  customerData?: UserModel | undefined;
  setCustomerData?: React.Dispatch<
    React.SetStateAction<UserModel | undefined>
  >;
  changeHandler?: (e: any) => void;
  dropDowns: TypeAllDropDownTables;
  fieldValidation?: TypeFieldValidations;
}

export const CustomerContext = createContext<ICustomerContext | null>(null);
