import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../../../commonComponents/rendering/originalReducer/IFields";

export var UserGridEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Id",
    width: 100,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 200,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 200,
  },
  {
    field: "lastLoginDT",
    headerName: "Last Login DT",
    width: 200,
  },
  {
    field: "tenantId",
    headerName: "Tenant Id",
    width: 200,
  },
  {
    field: "active",
    headerName: "Active",
    width: 200,
  },
  {
    field: "userTypeId",
    headerName: "User Type Id",
    width: 200,
  },
  {
    field: "balance",
    headerName: "Balance",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "Currency Id",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 200,
  },
];

export var UserGridAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "بطاقة تعريف",
    width: 100,
  },
  {
    field: "firstName",
    headerName: "الاسم الأول",
    width: 200,
  },
  {
    field: "lastName",
    headerName: "اسم العائلة",
    width: 200,
  },
  {
    field: "lastLoginDT",
    headerName: "تاريخ آخر تسجيل دخول",
    width: 200,
  },
  {
    field: "tenantId",
    headerName: "معرف المستأجر",
    width: 200,
  },
  {
    field: "active",
    headerName: "نشيط",
    width: 200,
  },
  {
    field: "userTypeId",
    headerName: "معرف نوع المستخدم",
    width: 200,
  },
  {
    field: "balance",
    headerName: "توازن",
    width: 200,
  },
  {
    field: "currencyId",
    headerName: "معرف العملة",
    width: 200,
  },
  {
    field: "email",
    headerName: "بريد إلكتروني",
    width: 200,
  },
  {
    field: "phoneNumber",
    headerName: "رقم التليفون",
    width: 200,
  },
];
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const UserDetailsEN: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Id",
      tag: "text",
      placeholder: "Id",
      length: 50,
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "First Name",
      tag: "text",
      placeholder: "FirstName",
    },
    {
      id: "password",
      name: "password",
      type: "text",
      label: "Current Password",
      tag: "text",
      placeholder: "Current Password",
    },
    {
      id: "updatePassword",
      name: "updatePassword",
      type: "checkbox",
      label: "Update Password",
      tag: "Checkbox",
      placeholder: "Update Password",
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "Last Name",
      tag: "text",
      placeholder: "LastName",
    },
    {
      id: "lastLoginDT",
      name: "lastLoginDT",
      type: "DateTime",
      label: "Last Login DT",
      tag: "DateTime",
      placeholder: "LastLoginDT",
      disabled: true,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "Tenant Id",
      tag: "select",
      placeholder: "TenantId",
      length: 50,
      options: dropdown?.tenants,
    },
    {
      id: "active",
      name: "active",
      type: "checkbox",
      label: "Active",
      tag: "Checkbox",
      placeholder: "Active",
    },
    {
      id: "userTypeId",
      name: "userTypeId",
      type: "text",
      label: "User Type Id",
      tag: "select",
      placeholder: "UserTypeId",
      options: dropdown?.userTypes,
    },
    {
      id: "balance",
      name: "balance",
      type: "number",
      label: "Balance",
      tag: "number",
      disabled: true,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "Currency",
      tag: "select",
      placeholder: "Currency",
      disabled: true,
    },
    {
      id: "email",
      name: "email",
      type: "text",
      label: "Email",
      tag: "text",
      placeholder: "Email",
    },
    {
      id: "phoneNumber",
      name: "honeNumber",
      type: "text",
      label: "Phone Number",
      tag: "text",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const UserDetailsAR: displayFieldsFunction = ({ dropdown, state }) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "بطاقة تعريف",
      tag: "text",
      placeholder: "Id",
      length: 50,
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "الاسم الأول",
      tag: "text",
      placeholder: "FirstName",
    },
    {
      id: "password",
      name: "password",
      type: "text",
      label: "كلمة السر الحالية",
      tag: "text",
      placeholder: "Current Password",
    },
    {
      id: "updatePassword",
      name: "updatePassword",
      type: "checkbox",
      label: "تطوير كلمة السر",
      tag: "Checkbox",
      placeholder: "Update Password",
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "اسم العائلة",
      tag: "text",
      placeholder: "LastName",
    },
    {
      id: "lastLoginDT",
      name: "lastLoginDT",
      type: "DateTime",
      label: "تاريخ آخر تسجيل دخول",
      tag: "DateTime",
      placeholder: "LastLoginDT",
      disabled: true,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "معرف المستأجر",
      tag: "select",
      placeholder: "TenantId",
      length: 50,
      options: dropdown?.tenants,
    },
    {
      id: "active",
      name: "active",
      type: "checkbox",
      label: "نشيط",
      tag: "Checkbox",
      placeholder: "Active",
    },
    {
      id: "userTypeId",
      name: "userTypeId",
      type: "text",
      label: "معرف نوع المستخدم",
      tag: "select",
      placeholder: "UserTypeId",
      options: dropdown?.userTypes,
    },
    {
      id: "balance",
      name: "balance",
      type: "number",
      label: "توازن",
      tag: "number",
      disabled: true,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "عملة",
      tag: "select",
      placeholder: "Currency",
      disabled: true,
    },
    {
      id: "email",
      name: "email",
      type: "text",
      label: "بريد إلكتروني",
      tag: "text",
      placeholder: "Email",
    },
    {
      id: "phoneNumber",
      name: "honeNumber",
      type: "text",
      label: "رقم التليفون",
      tag: "text",
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};
