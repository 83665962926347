import React, { FC, useEffect, useRef, useState } from "react";
import { IFields } from "../../../commonComponents/rendering/originalReducer/IFields";
import { Reducer } from "../../../commonComponents/rendering/originalReducer/Reducer";
import { ReportFields } from "./Fields";
import { ICardWorkbenchState } from "./Interfaces";
import Button from "react-bootstrap/Button";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faBinoculars } from "@fortawesome/free-solid-svg-icons";
import { useMessage, IUseMessageReturn } from "../../../Hooks/UseMessage";
import { Container } from "react-bootstrap";
import ExcelFileReader from "./FileReader";
import CardV2 from "../../../commonComponents/cards/fieldsCard/Card/CardV2";
import { useTranslation } from "react-i18next";
import useNavigateSearch from "../../../Hooks/useNavigationSearch";
import { IFetchReturn, useFetch } from "../../../Hooks/UseFetch";
import { GetBaseURL } from "../../../commonComponents/GlobalFiles/URLs";
import { useCard } from "../../../Hooks/UseCardReport";
import { useMessageI18n } from "../../../Hooks/useMessageI18n";
const CardWorkbench: FC = () => {
  const { t } = useTranslation();
  var GridFields: Array<GridColDef> = [
    {
      field: "id",
      headerName: t("id") ?? "",
      width: 100,
    },
    {
      field: "cardNumber",
      headerName: t("card_number") ?? "",
      width: 100,
    },
    {
      field: "serialNumber",
      headerName: t("serial_number") ?? "",
      width: 100,
    },
    {
      field: "vendorId",
      headerName: t("vendor") ?? "",
      width: 200,
    },
    {
      field: "categoryId",
      headerName: t("category") ?? "",
      width: 200,
    },
    {
      field: "subCategoryId",
      headerName: t("sub_category") ?? "",
      width: 200,
    },
    {
      field: "customerId",
      headerName: t("customer") ?? "",
      width: 200,
    },
    {
      field: "status",
      headerName: t("status") ?? "",
      width: 200,
    },
    {
      field: "orderId",
      headerName: t("order_id") ?? "",
      width: 200,
    },
    {
      field: "orderDT",
      headerName: t("order_dt") ?? "",
      width: 200,
    },
    {
      field: "tenantId",
      headerName: t("tenant_id") ?? "",
      width: 200,
    },
  ];
  const getDropDowns: IFetchReturn = useFetch("", "GET", true);
  const navigateSearch = useNavigateSearch();
  const [selectedId, setSelectedId] = useState<GridSelectionModel>([]);
  const [selection, setSelection] = useState<ICardWorkbenchState>({
    CardNumber: "",
    VendorId: "",
    CategoryId: "",
    SubCategoryId: "",
    Status: "",
  });

  const [gridData, setGridData] = useState<any>();
  const { refetch } = useCard();
  const selectionString = useRef<any>();
  const useMessagesI18n = useMessageI18n();

  const fieldsToRender = ReportFields({
    dropdown: getDropDowns.outData,
    state: selection,
  });

  function getAllDropDowns() {
    getDropDowns.fetchData({}, (res) => {}, GetBaseURL("AllDropDowns"));
  }

  useEffect(() => {
    getAllDropDowns();
  }, []);

  function changeHandler(e: any) {
    const { name, value } = e.target;
    setSelection((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      onChange: changeHandler,
      dataSource: { ...selection },
    };
    return newFieldObject;
  };

  const RunReport = async () => {
    let whereString: {} = {};
    let andWhereString: {} = {};

    if (selection?.CardNumber) {
      andWhereString = { cardNumber: { eq: selection.CardNumber } };
    }

    if (selection?.VendorId) {
      andWhereString = {
        ...andWhereString,
        vendorId: { eq: selection.VendorId },
      };
    }

    if (selection?.CategoryId) {
      andWhereString = {
        ...andWhereString,
        categoryId: { eq: selection.CategoryId },
      };
    }

    if (selection?.SubCategoryId) {
      andWhereString = {
        ...andWhereString,
        subCategoryId: { eq: selection.SubCategoryId },
      };
    }

    if (selection?.Status) {
      andWhereString = {
        ...andWhereString,
        status: { eq: selection.Status },
      };
    }

    whereString = { and: [andWhereString] };

    selectionString.current = whereString;

    let variableString: any = {
      whereString,
    };
    const { data } = await refetch(variableString);
    if (data?.cards.length > 0) {
      const newArray = data?.cards.map((item: any) => {
        const { __typename, ...rest } = item;
        return rest;
      });
      setGridData(newArray);
    } else {
      useMessagesI18n.addMessage("6", "E");
      return;
    }
  };

  function Create() {
    navigateSearch("/Card", { operation: "Create" });
  }

  function Change() {
    if (!selectedId[0]) {
      useMessagesI18n.addMessage("4", "E");
      return;
    }
    navigateSearch("/Card", { operation: "Change", id: selectedId });
  }

  function Display() {
    if (!selectedId[0]) {
      useMessagesI18n.addMessage("4", "E");
      return;
    }
    navigateSearch("/Card", { operation: "Display", id: selectedId });
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          gap: "20px",
        }}
      >
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={Create}
        >
          {t("create_card")}
        </Button>
        <Button
          className="btn btn-primary mt-1"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={RunReport}
        >
          {t("searchCards")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardV2
          cardHeaderProps={{
            headerLabel: "Card Workbench",
          }}
          cardBody={fieldsToRender.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
        />
        <ExcelFileReader
          vendorId={selection.VendorId ?? ""}
          categoryId={selection.CategoryId ?? ""}
          subCategoryId={selection.SubCategoryId ?? ""}
        />
      </div>

      <Button
        style={{
          background: "#08bd55",
          marginRight: "40px",
          borderColor: "#F2EFEE",
        }}
        onClick={() => {
          Display();
        }}
      >
        <FontAwesomeIcon
          icon={faBinoculars}
          style={{
            marginRight: "10px",
          }}
        />
        {t("display")}
      </Button>
      <Button
        style={{
          background: "#D51000",
          marginRight: "40px",
          borderColor: "#F2EFEE",
        }}
        onClick={() => {
          Change();
        }}
      >
        <FontAwesomeIcon
          icon={faPenSquare}
          style={{
            marginRight: "10px",
          }}
        />
        {t("change")}
      </Button>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={gridData || []}
          columns={GridFields}
          pageSize={10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(selection) => {
            const selectedRowData = gridData.filter(
              (row: any) => row.id == selection
            );
            setSelectedId(selection);
          }}
        />
      </Box>
    </Container>
  );
};

export default CardWorkbench;
