import React, { FC, useState, useEffect } from "react";
import { IAvailableCardsState } from "./Interfaces";
import { IFetchReturn, useFetch } from "../../Hooks/UseFetch";
import { MainArray } from "./Fields";
import { IFields } from "../../commonComponents/rendering/mUIReducer/IFields";
import Card from "../../commonComponents/cards/fieldsCard/Card/Card";
import { Reducer } from "../../commonComponents/rendering/mUIReducer/Reducer";
import Button from "react-bootstrap/Button";
import { CardsInventory } from "../../models/cards/CardInventory";
import SimpleGrid from "../../commonComponents/grids/simpleGrid/SimpleGrid";
import { AvailableCardItem } from "./GridFields";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UseFetch } from "../../Hooks/UseFetchNew";
import { BaseURLs, GetBaseURL } from "../../commonComponents/GlobalFiles/URLs";

const AvailableCards: FC = () => {
  const [selection, setSelection] = useState<IAvailableCardsState>({
    VendorId: "",
  });
  const { t } = useTranslation();
  const [showGrid, setShowGrid] = useState(false);

  const availableCards = UseFetch({
    url: `${BaseURLs?.Card}GetAvailableCards`,
    method: "GET",
    loading: true,
    noToasterMessages: true,
  });

  const getDropDowns: IFetchReturn = useFetch("", "GET", true);

  let itemFields = AvailableCardItem();
  const [itemData, setItemData] = useState(Array<any>);

  function gridClichHandler(data: {}) {}

  function getAllDropDowns() {
    getDropDowns.fetchData({}, (res) => {}, GetBaseURL("AllDropDowns"));
  }

  useEffect(() => {
    getAllDropDowns();
  }, []);

  const fieldsToRender = MainArray({
    dropdown: getDropDowns.outData,
    state: selection,
  });

  function changeHandler(e: any) {
    const { name, value } = e.target;
    setSelection((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      onChange: changeHandler,
      dataSource: { ...selection },
    };
    return newFieldObject;
  };

  return (
    <Container>
      <Card
        cardHeaderProps={{
          headerLabel: "Report Selection",
        }}
        cardBody={fieldsToRender.map((field, index) => (
          <Reducer key={index} {...mergedFieldData(field)} />
        ))}
        style={{ width: "60%" }}
      />
      <Button
        onClick={() => {
          setShowGrid(false);
          setItemData([]);

          if (selection.VendorId && selection.VendorId !== "select") {
            availableCards.fetchData({
              params: {
                id: selection.VendorId,
              },
              onSuccess: ({ data }) => {
                if (data) {
                  setItemData(data);
                  setShowGrid(true);
                }
              },
              onError: (res) => {},
            });
          }
        }}
        style={{
          width: "20%",
          background: "#33FF5E",
          borderColor: "#DA1212",
        }}
      >
        {t("run")}
      </Button>
      {showGrid === true ? (
        <SimpleGrid
          Fields={itemFields}
          data={itemData}
          ActionColumn={false}
          handleButtonClickGrid={gridClichHandler}
        />
      ) : (
        <div> </div>
      )}
    </Container>
  );
};

export default AvailableCards;
