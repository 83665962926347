import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Login from "../login/Login";
import { useAppSelector } from "../../redux/hooks";
const ProtectedRoutes: FC = () => {
  const userinfo = useAppSelector((state) => state.userInfo);
  if (!userinfo.loggedIn) return <Login />;
  else return <Outlet />;
};

export default ProtectedRoutes;
