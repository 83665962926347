import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CustomerContext, ICustomerContext } from "./Common/CustomerContext";
import Header from "./Common/Header";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MainData from "./Main/MainData";
import AdminData from "./Admin/AdminData";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useMessageI18n } from "../../../Hooks/useMessageI18n";
import { BaseURLs } from "../../../commonComponents/GlobalFiles/URLs";
import { TypeFieldValidations } from "../../../types/TypeFieldValidation";
import { useSchema } from "../../../Hooks/useSchema";
import { useTranslation } from "react-i18next";
import { UseFetch } from "../../../Hooks/UseFetchNew";
import useNavigateSearch from "../../../Hooks/useNavigationSearch";
import { UserModel } from "../../../models/User/User";
const CustomerMain: FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const useMessagesI18n = useMessageI18n();
  const { CustomerSchema } = useSchema();
  const [operation, setOperation] = useState<string>("");
  const [customerId, setCustomerId] = useState("");
  const [customerData, setCustomerData] = useState<UserModel>();
  const [key, setKey] = useState<any>("main");
  const [searchParams] = useSearchParams();
  const [fieldValidation, setFieldValidation] = useState<
    TypeFieldValidations | undefined
  >();

  const navigateSearch = useNavigateSearch();
  const userInfo = useAppSelector((state) => state.userInfo);
  const dropDowns = useAppSelector((state) => state.dropDowns);
  const saveCustomer = UseFetch({
    url:
      operation === "Create"
        ? `${BaseURLs?.User}CreateUser`
        : `${BaseURLs?.User}UpdateUser`,
    method: operation === "Create" ? "POST" : "PUT",
    loading: true,
    noToasterMessages: true,
  });

  const getCustomer = UseFetch({
    url: `${BaseURLs?.Customer}GetCustomer`,
    method: "GET",
    loading: true,
    noToasterMessages: true,
  });

  // const getDropDowns = UseFetch({
  //   url: BaseURLs.AllDropDowns,
  //   method: "GET",
  //   loading: true,
  //   noToasterMessages: true,
  // });

  useEffect(() => {
    //the use of this useEffect is to extract the search parameters
    let searchParamObject = Object.fromEntries(searchParams);
    //If there is not supplied operation then set it to Create

    if (searchParamObject?.operation) {
      setOperation(searchParamObject.operation);
    } else {
      navigateSearch("/Customer", { operation: "Create" });
    }
    if (searchParamObject?.id) {
      setCustomerId(searchParamObject?.id);
    }
    //added the navigate as a dependecy becuase it needed when change path is called
    //after partner is created is saved
  }, [navigate]);

  useEffect(() => {
    if (customerId) {
      getCustomer.fetchData({
        params: { id: customerId },
        onSuccess: ({ data }) => {
          setCustomerData(data);
          if (operation == "Create") {
            setCustomerData((prevData) => ({
              ...prevData,
              id: "",
              modifiedBy: "",
              modifiedOn: undefined,
              createdBy: "",
              createdOn: undefined,
            }));
            setCustomerId("");
          }
        },
        onError: (res) => {},
      });
    }
  }, [customerId]);

  useEffect(() => {
    // getDropDowns.fetchData({
    //   onSuccess: ({ data }) => {},
    //   onError: (res) => {},
    // });

    //Customers can not view customer(s), this an admin only function
    if (userInfo.userTypeId == "Customer") {
      navigate("/Home");
    }

    setCustomerData((prev) => ({
      ...prev,
      userTypeId: "Customer",
      validFromDT: new Date(),
      tenantId: userInfo.tenantId,
      currencyId: userInfo.currencyId ?? "",
    }));
  }, []);

  function changeHandler(e: any) {
    const { name, value, type, checked } = e.target;
    let newValue: any;
    switch (type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        //if checkbox then pass the value as true or false assuming that field is defined as bool in backend
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    setCustomerData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  }

  const contextState: ICustomerContext = {
    operation,
    customerId,
    customerData,
    setCustomerData,
    changeHandler,
    dropDowns: dropDowns.data,
    fieldValidation,
  };
  return (
    <CustomerContext.Provider value={contextState}>
      <div className="container-fluid">
        <Header>
          <Button
            className="btn btn-primary mt-1"
            style={{
              height: "3504ev",
              background: "#08bd55",
              borderColor: "#08bd55",
              fontSize: "40px",
            }}
            onClick={() => {
              if (!customerData) {
                useMessagesI18n.addMessage("5", "E");
                return;
              }
              CustomerSchema.validate(customerData, { abortEarly: false })
                .then((responseData) => {
                  saveCustomer.fetchData({
                    callingData: {
                      ...customerData,
                    },
                    onSuccess: ({ data }) => {
                      if (data) {
                        setCustomerData(data);
                      }
                      if (operation === "Create") {
                        setOperation("Change");
                        setCustomerId(data.id);
                        navigateSearch("/Customer", {
                          id: data.id,
                          operation: "Change",
                        });
                      }
                    },
                    onError: (res) => {},
                  });
                })
                .catch((err) => {
                  setFieldValidation(
                    err.inner.map((e: any) => ({
                      id: e.path,
                      message: e.message,
                    }))
                  );
                  return;
                });
            }}
          >
            {t("save")}
          </Button>
        </Header>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
          }}
          className="mb-3"
        >
          <Tab eventKey="main" title={t("main")}>
            <MainData />
          </Tab>
          <Tab eventKey="admin" title={t("admin")}>
            <h1>
              <AdminData />
            </h1>
          </Tab>
        </Tabs>
      </div>
    </CustomerContext.Provider>
  );
};

export default CustomerMain;
