export const BaseURLs = {
  Host: "http://localhost:7253/",
  //Host: "https://RyanCards.com:7253/",
  Config: "api/Configuration/",
  Order: "api/Order/",
  DirectCharge: "api/DirectCharge/",
  FIDOC: "api/FIDoc/",
  InternalConfig: "api/InternalConfig/",
  MasterData: "api/MasterData/",
  DropDowns: "api/DropDown/",
  Card: "api/Card/",
  User: "api/User/",
  AllDropDowns: "api/DropDown/GetAllDropDowns",
  Authenticate: "api/User/Authenticate",
  GraphQL: "graphql/",
  Customer: "api/MasterData/",
  Price: "api/Price/",
};

export const EndPoints = {
  User: "/api/User/",
};

export type getURLFunction = (baseURL: string) => string;

export const GetBaseURL: getURLFunction = (baseURL) => {
  type ObjectKey = keyof typeof BaseURLs;
  let fullUrl = BaseURLs.Host + BaseURLs[baseURL as ObjectKey];
  return fullUrl;
};
