import React, { FC, useState, useEffect, useRef } from "react";
import { IFetchReturn, useFetch } from "./../../Hooks/UseFetch";
import { TableDetails, TableGridFields } from "./Fields/TableField";
import Button from "react-bootstrap/Button";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFields } from "../../commonComponents/rendering/originalReducer/IFields";
import {
  faPenSquare,
  faTrash,
  faBinoculars,
} from "@fortawesome/free-solid-svg-icons";

import { Reducer } from "../../commonComponents/rendering/originalReducer/Reducer";
import { GetBaseURL } from "./../../commonComponents/GlobalFiles/URLs";
import Pluralize from "pluralize";
import { TableSetting } from "./TableSettings";
import { useMessage, IUseMessageReturn } from "./../../Hooks/UseMessage";
import { useAppSelector } from "./../../redux/hooks";
import { useTranslation } from "react-i18next";
interface ITableMaintenanceMain {
  TableName: string;
}

const TableMaintenanceMain: FC<ITableMaintenanceMain> = ({ TableName }) => {
  const useMessages: IUseMessageReturn = useMessage();
  const [showDetMod, setShowDetMod] = useState(false);
  const [selectedId, setSelectedId] = useState<any>("");
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [singleRecord, setSingleRecord] = useState<{}>();
  const [allRecords, setAllRecords] = useState<Array<any>>([]);
  const [allDropDowns, setAllDropDowns] = useState<Array<any>>([]);
  const [tableDetailFields, setTableDetailFields] = useState<Array<IFields>>(
    []
  );
  const { t, i18n } = useTranslation();

  const getAllEntries: IFetchReturn = useFetch("", "GET", true);
  const getSingleRecord: IFetchReturn = useFetch("", "GET", true);
  const updateSingleRecord: IFetchReturn = useFetch("", "PUT", true);
  const createSingleRecord: IFetchReturn = useFetch("", "POST", true);
  const deleteSingleRecord: IFetchReturn = useFetch("", "DELETE", true);
  const getDropDowns: IFetchReturn = useFetch("", "GET", true);
  const tableGridFields = useRef<Array<GridColDef>>([]);
  const opeartion = useRef<string>("");
  const fullBaseURL = useRef<string>("");
  //Get Table fields for detail view
  // var fullBaseURL = GetBaseURL(baseURL);
  const dropDowns = useAppSelector((state) => state.dropDowns);
  const user = useAppSelector((state) => state.userInfo);
  useEffect(() => {
    //Render table grid view fields and table setting, these must re-run when ONLY the
    //table name changes. The gtid view fields does not need the dropdown or state of record selected
    var language = i18n.language === "ar" ? "AR" : "EN";
    let tableGridAccessor: string = `${TableName}Grid${language}`;
    let tableSettingsAccessor: string = `${TableName}`;
    type ObjectKey2 = keyof typeof TableGridFields;
    type ObjectKey3 = keyof typeof TableSetting;

    var gridFieldstoRender = TableGridFields[tableGridAccessor as ObjectKey2];
    if (!gridFieldstoRender) {
      useMessages.addMessage("3", "E", TableName);
      return;
    }

    tableGridFields.current = gridFieldstoRender;

    var tableSettings = TableSetting[tableSettingsAccessor as ObjectKey3];

    fullBaseURL.current = GetBaseURL(tableSettings.baseURL);
    getAllRecords();
  }, [TableName]);

  useEffect(() => {
    //This use effect get the table details fields and their dropdowns, it must
    //re-run when: 1- table names, 2- dropdowns and 3- record selected(this must be added to array, have not done yet)
    var language = i18n.language === "ar" ? "AR" : "EN";
    let tableDatailAccessor: string = `${TableName}Details${language}`;

    type ObjectKey1 = keyof typeof TableDetails;

    var detailFieldstoRender = TableDetails[tableDatailAccessor as ObjectKey1];

    if (!detailFieldstoRender) {
      useMessages.addMessage("2", "E", TableName);
      return;
    }
    setTableDetailFields(detailFieldstoRender({ dropdown: allDropDowns }));
  }, [allDropDowns, TableName]);

  useEffect(() => {
    getAllDropDowns();
  }, []);

  function changeHandler(e: any) {
    const { name, value, checked } = e.target;

    let newValue: any;
    switch (e.target.type) {
      case "number":
        newValue = Number(value);
        break;
      case "checkbox":
        //if checkbox then pass the value as true or false assuming that field is defined as bool in backend
        checked ? (newValue = true) : (newValue = false);
        break;
      default:
        newValue = value;
        break;
    }
    setSingleRecord((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...singleRecord },
      onChange: changeHandler,
    };
    //Code for user, if admin is accessing the setting tree, they can
    // change the tenant ID or user type becuase they can only create a customer
    if (TableName === "User") {
      if (user.userTypeId === "Admin") {
        if (field.id === "tenantId" || field.id === "userTypeId") {
          newFieldObject.disabled = true;
        }
      }
    }

    if (!newFieldObject.disabled) {
      if (disableFields) {
        newFieldObject.disabled = true;
      }
    }
    return newFieldObject;
  };

  function getAllDropDowns() {
    getDropDowns.fetchData(
      {},
      (res) => {
        setAllDropDowns(res.data);
      },
      GetBaseURL("AllDropDowns")
    );
  }
  function getRecord() {
    let x = `${fullBaseURL.current}Get${TableName}?id=${selectedId}`;
    getSingleRecord.fetchData(
      {},
      (res) => {
        setSingleRecord(res.data);
      },
      x
    );
  }
  function saveRecord() {
    if (opeartion.current == "Change") {
      let x = `${fullBaseURL.current}Update${TableName}`;
      updateSingleRecord.fetchData(
        { ...singleRecord },
        (res) => {
          setSingleRecord(res.data);
          setShowDetMod(false);
          getAllRecords();
        },
        x
      );
    }
    if (opeartion.current == "Create") {
      let x;
      if (TableName === "Vendor") {
        x = `${fullBaseURL.current}Save${TableName}`;
      } else {
        x = `${fullBaseURL.current}Create${TableName}`;
      }

      createSingleRecord.fetchData(
        { ...singleRecord },
        (res) => {
          setSingleRecord(res.data);
          setShowDetMod(false);
          getAllRecords();
        },
        x
      );
    }
  }

  function getAllRecords() {
    debugger;
    var pluralComponentName: string = Pluralize(TableName);
    let fullURL: string = `${fullBaseURL.current}GetAll${pluralComponentName}`;

    getAllEntries.fetchData(
      {},
      ({ data }) => {
        setAllRecords(data);
      },
      fullURL
    );
  }

  function displayRecord() {
    getRecord();
    setDisableFields(true);
    setShowDetMod(true);
  }

  function deleteRecotd() {
    let x = `${fullBaseURL.current}Delete${TableName}?id=${selectedId}`;
    deleteSingleRecord.fetchData(
      {},
      (res) => {
        getAllRecords();
      },
      x
    );
  }
  function changeRecord() {
    getRecord();
    setDisableFields(false);
    setShowDetMod(true);
  }
  return (
    <>
      <Button
        className="my-3"
        //        style={{ background: "#008000", borderColor: "#008000" }}
        data-op="Change"
        onClick={() => {
          //special code for user, an admin can only create a customer
          if (TableName === "User") {
            if (user.userTypeId === "Admin") {
              setSingleRecord({
                tenantId: user.tenantId,
                userTypeId: "Customer",
              });
            }
          } else {
            setSingleRecord({});
          }
          opeartion.current = "Create";
          setDisableFields(false);
          setShowDetMod(true);
        }}
      >
        {t("create")}
      </Button>

      <Box sx={{ height: 800, width: "100%" }}>
        <DataGrid
          rows={allRecords}
          columns={tableGridFields.current}
          pageSize={20}
          rowsPerPageOptions={[20]}
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(selection) => {
            const selectedRowData = allRecords.filter(
              (row) => row.id == selection
            );
            setSelectedId(selection);
          }}
        />
      </Box>
      <div className="mt-3">
        <Button
          className="btn-danger"
          onClick={() => {
            opeartion.current = "Display";
            displayRecord();
          }}
        >
          <FontAwesomeIcon icon={faBinoculars} />
          {t("display")}
        </Button>
        {TableName !== "CustomerCatPrice" &&
          TableName !== "CustomerSubCatPrice" && (
            <Button
              className="mx-2"
              //  style={{ background: "#008000", borderColor: "#008000" }}
              onClick={() => {
                opeartion.current = "Change";
                changeRecord();
              }}
            >
              <FontAwesomeIcon icon={faPenSquare} />
              {t("change")}
            </Button>
          )}
        <Button
          className="btn-danger"
          style={{ background: "#dc3545", borderColor: "#dc3545" }}
          onClick={() => {
            deleteRecotd();
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
          {t("delete")}
        </Button>
      </div>
      <Modal
        show={showDetMod}
        animation={false}
        dialogClassName="dialogClassName"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            {t("table_maintenance_name", { tableName: TableName })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tableDetailFields.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setShowDetMod(false);
            }}
            style={{
              borderRadius: 10,
            }}
          >
            {t("close")}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              saveRecord();
            }}
            //          disabled={mainButtonStatus()}
            data-dismiss="modal"
            style={{
              borderRadius: 10,
            }}
          >
            {t("save")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableMaintenanceMain;
