import React, { useEffect } from "react";
import Navigation from "./admin/navigations/Navigation";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  createHttpLink,
} from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import { BaseURLs, GetBaseURL } from "./commonComponents/GlobalFiles/URLs";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MessageProvider from "./admin/providers/MessageProvider";
import { LoadingProvider } from "./commonComponents/loaders/newLoader/LoadingProvder";

function App() {
  const {
    i18n: { language },
  } = useTranslation();
  const httpLink = createHttpLink({
    uri: GetBaseURL("GraphQL"),
  });
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("rc_token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  axios.defaults.baseURL = BaseURLs.Host;
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      throw error;
    }
  );

  axios.interceptors.request.use(
    (config) => {
      config.headers = config.headers ?? {};
      config.headers["Authorization"] =
        `Bearer ${localStorage.getItem("rc_token")}` ?? {};
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    if (language == "en-US" || language == "EN" || language == "en") {
      document.body.dir = "ltr";
    } else if (language == "AR") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, []);

  return (
    <LoadingProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <MessageProvider>
            <Navigation />
            <ToastContainer />
          </MessageProvider>
        </Provider>
      </ApolloProvider>
      {/* <ReactQueryDevtools /> */}
    </LoadingProvider>
  );
}

export default App;
