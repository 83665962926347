import { Message, UseFormRegister } from "react-hook-form";
import { IFields } from "../../commonComponents/rendering/originalReducer/IFields";

export type displayFieldsFunction<T = any> = (optParam?: T) => IFields[];

export const loginArray: displayFieldsFunction = ({ dataSource }) => [
  {
    id: "userName",
    name: "userName",
    type: "text",
    label: "UserId",
    tag: "input",
    placeholder: "Type User ID here",
    required: true,
    dataSource,
  },
  {
    id: "password",
    name: "password",
    type: "password",
    label: "Password",
    tag: "input",
    placeholder: "Type Password here",
    required: true,
    dataSource,
  },
  {
    id: "language",
    name: "language",
    type: "text",
    label: "Language",
    tag: "select",
    placeholder: "Language",
    options: langDD,
    required: true,
    dataSource,
  },
];

let langDD: Array<{ value: string; label: string }> = [
  { value: "EN", label: "English" },
  { value: "AR", label: "Arabic" },
];
