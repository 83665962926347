import React, { useState, useRef, useEffect } from "react";
import { read, utils } from "xlsx";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import "./Card.scss";
import { CardModel } from "../../../models/MasterData/Card";
import { IFetchReturn } from "../../../Hooks/UseFetch";
import { BaseURLs } from "../../../commonComponents/GlobalFiles/URLs";
import { UseFetch } from "../../../Hooks/UseFetchNew";
import { useTranslation } from "react-i18next";

const ExcelFileReader: React.FC<{
  vendorId: string;
  categoryId: string;
  subCategoryId: string;
}> = ({ vendorId, categoryId, subCategoryId }) => {
  const [itemsList, setItemsList] = useState<CardModel[]>([]);
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const saveCards = UseFetch({
    url: `${BaseURLs?.Card}SaveCards`,
    method: "POST",
    loading: true,
    noToasterMessages: true,
  });

  useEffect(() => {
    setItemsList([]);

    if (fileInputRef.current && fileInputRef.current.files?.length) {
      handleFileChange(fileInputRef.current.files[0]);
    }
  }, [vendorId, categoryId, subCategoryId]);

  const handleFileChange = (file: File) => {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const data = e.target?.result;
      if (!data) return;

      const workbook = read(data, { type: "binary" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const excelData = utils.sheet_to_json(worksheet, { header: 1 });

      const newItems: CardModel[] = excelData.map((row: any) => ({
        cardNumber: row[0],
        serialNumber: row[1],
        vendorId: vendorId,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
      }));

      setItemsList(newItems);
    };

    fileReader.readAsBinaryString(file);
  };

  return (
    <div>
      <div className="inputFileRow">
        <label htmlFor="fileInput" className="inputFileLabel">
          <FontAwesomeIcon icon={faUpload} className="btnIcon withGap" />
          {t("upload_file")}
        </label>
        <input
          type="file"
          id="fileInput"
          onChange={(e) => {
            if (e.target.files?.length) {
              handleFileChange(e.target.files[0]);
            }
          }}
          ref={fileInputRef}
          className="inputFile"
        />
        {itemsList.length > 0 && (
          <div>
            <h2>
              {t("items_found")}: {itemsList.length}
            </h2>
          </div>
        )}
        <Button
          className="inputFileLabel"
          style={{
            height: "3504ev",
            background: "#08bd55",
            borderColor: "#08bd55",
            fontSize: "30px",
          }}
          onClick={() => {
            if (!itemsList) {
              return;
            }
            //Converting elements of upload excell to string
            let convertedArray = itemsList.map((element, index) => {
              element.cardNumber = String(element.cardNumber);
              element.serialNumber = String(element.serialNumber);
              return element; // Keep the element unchanged
            });

            saveCards.fetchData({
              callingData: {
                cards: convertedArray,
              },
              onSuccess: ({ data }) => {
                if (data) {
                  setItemsList(data);
                }
              },
              onError: (res) => {},
            });
          }}
        >
          {t("save_cards")}
        </Button>
      </div>
      {itemsList.length > 0 && (
        <div className="tableContainer">
          <table className="itemGrid">
            <thead>
              <tr>
                <th>{t("card_number")}</th>
                <th>{t("serial_number")}</th>
                <th>{t("vendor")}</th>
                <th>{t("category")}</th>
                <th>{t("sub_category")}</th>
              </tr>
            </thead>
            <tbody>
              {itemsList.map((item, index) => (
                <tr key={index}>
                  <td>{item.cardNumber}</td>
                  <td>{item.serialNumber}</td>
                  <td>{item.vendorId}</td>
                  <td>{item.categoryId}</td>
                  <td>{item.subCategoryId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ExcelFileReader;
