import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import {IUser} from '../../models/interfaces/Iuser'
// Define the initial state using that type
const initialState: any = {

}

export const DropDownSlice = createSlice({
    name: "userData",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    newDropDown(state, action) {
     return state = {
        ...action.payload
      };

    },
    clearDropDown(state) {
     return  state = initialState;

    },
  },
})

export const { newDropDown, clearDropDown } = DropDownSlice.actions


export default DropDownSlice.reducer