import {
  useQuery,
  useLazyQuery,
  gql,
  QueryResult,
  LazyQueryExecFunction,
  ApolloError,
} from "@apollo/client";
import { IuseReturn } from "./IUseReturn";

// export interface IuseDirectChargeOrderReportReturn {
//   execMe: LazyQueryExecFunction<any, any>;
//   data: any;
//   loading: boolean;
//   error: ApolloError | undefined;
// }
// export const useDirectChargeOrderReport = (
//   selection: any
// ): IuseDirectChargeOrderReportReturn => {
//   let op: string = "eq";
//   let whereString: {} = {};

//   let x: {} = {};

//   if (selection.CustomerId) {
//     whereString = {
//       ...whereString,
//       customerId: { [op]: selection.CustomerId },
//     };
//   }

//    if (selection.DocDate) {
//      whereString = {
//        ...whereString,
//        docDate: { 'gt': selection.DocDate },
//      };
//    }

//   if (selection.VendorId) {
//     whereString = { ...whereString, vendorId: { [op]: selection.VendorId } };
//   }

//   let variableString: any = {
//     whereString,
//   };

//   const dynamicQuery = gql`
//     query getAll($whereString: DirectChargeOrderFilterInput!) {
//       directChargeOrders(where: $whereString) {
//         id
//         vendorId
//         account1
//         account2
//         docDate
//         docTypeId
//         amount
//         currencyId
//         customerId
//         statusId
//         completedDate
//         completedBy
//         cancelededDate
//         canceledBy
//       }
//     }
//   `;

//   const [execMe, { loading, error, data }] = useLazyQuery(dynamicQuery, {
//     variables: variableString,
//   });

//   return { execMe, data, loading, error };
// };

export const useDirectChargeOrder = (): IuseReturn => {
  const useDirectChargeOrderReport = gql`
    query GetDirectChargeOrders($whereString: DirectChargeOrderFilterInput!) {
      directChargeOrders(where: $whereString) {
        id
        vendorId
        account1
        account2
        docDate
        docTypeId
        amount
        currencyId
        customerId
        statusId
        completedDate
        completedBy
        cancelededDate
        canceledBy
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(
    useDirectChargeOrderReport,
    {
      skip: true, // Skip the initial execution
    }
  );

  return { refetch, data, loading, error };
};
