import React, { FC, useContext } from "react";
import Card from "../../../../commonComponents/cards/fieldsCard/Card/Card";
import { IFields } from "../../../../commonComponents/rendering/originalReducer/IFields";
import { Reducer } from "../../../../commonComponents/rendering/originalReducer/Reducer";
import { useAppSelector } from "../../../../redux/hooks";
import { DirectChargeOrderContext } from "../Common/DirectChargeOrderContext";
import { OrderMain1, OrderAdmin1 } from "./Fields";

const MainData: FC = () => {
  const contextData = useContext(DirectChargeOrderContext);
  const userInfo = useAppSelector((state) => state.userInfo);

  const fieldsToRender1 = OrderMain1({
    dropdown: contextData?.dropDowns,
    state: contextData?.orderData,
  });

  const fieldsToRender2 = OrderAdmin1({
    dropdown: contextData?.dropDowns,
    state: contextData?.orderData,
  });

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...contextData?.orderData },
      onChange: contextData?.changeHandler,
    };

    if (userInfo.userTypeId == "Customer") {
      if (newFieldObject.id == "customerId") {
        newFieldObject.disabled = true;
      }
    }

    if (contextData?.operation == "Change") {
      newFieldObject.disabled = true;
    }

    return newFieldObject;
  };

  return (
    <div className="row">
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={fieldsToRender1.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
      <div className="col-4" style={{ fontSize: "20px" }}>
        <Card
          cardHeaderProps={{
            headerLabel: "",
          }}
          cardBody={fieldsToRender2.map((field, index) => (
            <Reducer key={index} {...mergedFieldData(field)} />
          ))}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
};

export default MainData;
