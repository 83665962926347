import React, { FC, useState, useEffect } from "react";
import { ConfigTables } from "./Fields";
import { IFields } from "../../../commonComponents/rendering/mUIReducer/IFields";
import { Reducer } from "../../../commonComponents/rendering/mUIReducer/Reducer";
import { useAppSelector } from "./../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import TableMaintenanceMain from "../../../admin/TableMaintenance/TableMaintenanceMain";
import { Container } from "react-bootstrap";
const MasterSettingTree: FC = () => {
  let navigate = useNavigate();
  type TypeTable = {
    table: string;
  };

  const [table, setTable] = useState<TypeTable>({ table: "" });
  let allConfigTables = ConfigTables({});
  const userInfo = useAppSelector((state) => state.userInfo);
  useEffect(() => {
    //Customer can not display customer(s), this an admin only function
    if (userInfo.userTypeId == "Customer") {
      navigate("/Home");
    }
  }, []);

  function changeHandler(e: any) {
    const { name, value } = e.target;
    setTable((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const mergedFieldData = (field: IFields) => {
    let newFieldObject: IFields = {
      ...field,
      dataSource: { ...table },
      onChange: changeHandler,
    };

    return newFieldObject;
  };

  if (table?.table) {
    return (
      <Container>
        {allConfigTables.map((field, index) => (
          <Reducer key={index} {...mergedFieldData(field)} />
        ))}

        <TableMaintenanceMain TableName={table?.table || ""} />
      </Container>
    );
  } else {
    return (
      <Container>
        {allConfigTables.map((field, index) => (
          <Reducer key={index} {...mergedFieldData(field)} />
        ))}
      </Container>
    );
  }
};

export default MasterSettingTree;
