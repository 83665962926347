import { useTranslation } from "react-i18next";
import { IFields } from "../commonComponents/rendering/originalReducer/IFields";
import { UserModel } from "../models/User/User";

export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const MainArray: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: t("user_id"),
      tag: "input",
      keyField: true,
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: t("first_name"),
      tag: "input",
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: t("last_name"),
      tag: "input",
    },
    {
      id: "email",
      name: "email",
      type: "text",
      label: t("email"),
      tag: "input",
    },
    {
      id: "phoneNumber",
      name: "phoneNumber",
      type: "text",
      label: t("phone_number"),
      tag: "input",
    },
  ];
};

export const AdminArray: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "lastLoginDT",
      name: "lastLoginDT",
      type: "DateTime",
      label: t("last_login_dt"),
      tag: "DateTime",
      disabled: true,
    },
    {
      id: "validFromDT",
      name: "validFromDT",
      type: "DateTime",
      label: t("valid_from_dt"),
      tag: "DateTime",
      disabled: true,
    },
    {
      id: "validToDT",
      name: "validToDT",
      type: "DateTime",
      label: t("valid_to_dt"),
      tag: "DateTime",
      disabled: true,
    },
  ];
};
export const FinancialArray: displayFieldsFunction = (data) => {
  const { t } = useTranslation();
  return [
    {
      id: "balance",
      name: "balance",
      type: "number",
      label: t("balance"),
      tag: "input",
      disabled: true,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: t("tenant"),
      tag: "select",
      disabled: true,
      options: data?.dropdown?.tenants,
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: t("currency"),
      tag: "select",
      options: data?.dropdown?.currencies,
      disabled: true,
    },
  ];
};
