import { GridColDef } from "@mui/x-data-grid";
import { IFields } from "../../../../../commonComponents/rendering/originalReducer/IFields";

export var CustomerSubCatPriceGridEN: Array<GridColDef> = [
  {
    field: "id",
    headerName: "Category Price",
    width: 320,
  },
  {
    field: "customerId",
    headerName: "Customer",
    width: 200,
  },
  {
    field: "subCategoryId",
    headerName: "Sub Category",
    width: 200,
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
  },
];

export var CustomerSubCatPriceGridAR: Array<GridColDef> = [
  {
    field: "id",
    headerName: "سعر الفئة",
    width: 100,
  },
  {
    field: "customerId",
    headerName: "عميل",
    width: 200,
  },
  {
    field: "subCategoryId",
    headerName: "تصنيف فرعي",
    width: 200,
  },
  {
    field: "price",
    headerName: "سعر",
    width: 100,
  },
];
export interface IFieldsParam {
  dropdown?: any;
  state?: any;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const CustomerSubCatPriceDetailsEN: displayFieldsFunction = ({
  dropdown,
  state,
}) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "Id",
      tag: "text",
      placeholder: "Id",
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "Customer",
      tag: "select",
      placeholder: "Customer Id",
      options: dropdown?.customers,
    },
    {
      id: "subCategoryId",
      name: "subCategoryId",
      type: "text",
      label: "Sub Category",
      tag: "select",
      placeholder: "Sub Category Id",
      options: dropdown?.subCategories,
    },
    {
      id: "price",
      name: "price",
      type: "number",
      label: "Price",
      placeholder: "Price",
      tag: "input",
    },
    {
      id: "discountPercentage",
      name: "discountPercentage",
      type: "number",
      label: "Discount Percentage",
      placeholder: "Discount Percentage",
      tag: "input",
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "Currency",
      tag: "select",
      placeholder: "Currency",
      options: dropdown?.currencies,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "Tenant Id",
      tag: "select",
      placeholder: "TenantId",
      options: dropdown?.tenants,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "Created By",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "Created On",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "Modified By",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "Modified On",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};

export const CustomerSubCatPriceDetailsAR: displayFieldsFunction = ({
  dropdown,
  state,
}) => {
  return [
    {
      id: "id",
      name: "id",
      type: "text",
      label: "بطاقة تعريف",
      tag: "text",
      placeholder: "Id",
    },
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "عميل",
      tag: "select",
      placeholder: "Customer Id",
      options: dropdown?.customers,
    },
    {
      id: "subCategoryId",
      name: "subCategoryId",
      type: "text",
      label: "تصنيف فرعي",
      tag: "select",
      placeholder: "Sub Category Id",
      options: dropdown?.subCategories,
    },
    {
      id: "price",
      name: "price",
      type: "number",
      label: "سعر",
      placeholder: "Price",
      tag: "input",
    },
    {
      id: "discountPercentage",
      name: "discountPercentage",
      type: "number",
      label: "نسبة الخصم",
      placeholder: "Discount Percentage",
      tag: "input",
    },
    {
      id: "currencyId",
      name: "currencyId",
      type: "text",
      label: "عملة",
      tag: "select",
      placeholder: "Currency",
      options: dropdown?.currencies,
    },
    {
      id: "tenantId",
      name: "tenantId",
      type: "text",
      label: "مستأجر",
      tag: "select",
      placeholder: "TenantId",
      options: dropdown?.tenants,
    },
    {
      id: "createdBy",
      name: "createdBy",
      type: "text",
      label: "انشأ من قبل",
      tag: "text",
      placeholder: "Created By",
      disabled: true,
    },
    {
      id: "createdOn",
      name: "createdOn",
      type: "DateTime",
      label: "تم إنشاؤها على",
      tag: "DateTime",
      placeholder: "Created On",
      disabled: true,
    },
    {
      id: "modifiedBy",
      name: "modifiedBy",
      type: "text",
      label: "تم التعديل بواسطة",
      tag: "text",
      placeholder: "modifiedBy",
      disabled: true,
    },
    {
      id: "modifiedOn",
      name: "modifiedOn",
      type: "DateTime",
      label: "تعديل على",
      tag: "DateTime",
      placeholder: "modifiedOn",
      disabled: true,
    },
  ];
};
