import { CardModel } from "../../../../models/MasterData/Card";
import { IFields } from "../../../../commonComponents/rendering/mUIReducer/IFields";
import { useTranslation } from "react-i18next";

export interface IFieldsParam {
  dropdown?: any;
  state?: CardModel;
}

export type displayFieldsFunction = (params: IFieldsParam) => IFields[];

export const OrderArrayEN: displayFieldsFunction = (data) => {
  return [
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "Customer Id",
      tag: "selectMui",
      options: data?.dropdown?.customers,
      disabled: true,
    },
    {
      id: "orderId",
      name: "orderId",
      type: "text",
      label: "Order Id",
      tag: "inputMui",
      placeholder: "Order",
      disabled: true,
    },
    {
      id: "orderDT",
      name: "orderDT",
      type: "DateTime",
      label: "Order DT",
      tag: "DateTime",
      disabled: true,
    },
  ];
};

export const OrderArrayAR: displayFieldsFunction = (data) => {
  return [
    {
      id: "customerId",
      name: "customerId",
      type: "text",
      label: "هوية الزبون",
      tag: "selectMui",
      options: data?.dropdown?.customers,
      disabled: true,
    },
    {
      id: "orderId",
      name: "orderId",
      type: "text",
      label: "رقم التعريف الخاص بالطلب",
      tag: "inputMui",
      placeholder: "Order",
      disabled: true,
    },
    {
      id: "orderDT",
      name: "orderDT",
      type: "DateTime",
      label: "تاريخ الطلب",
      tag: "DateTime",
      disabled: true,
    },
  ];
};
