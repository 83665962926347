import gql from "graphql-tag";
import { IuseReturn } from "./IUseReturn";
import { useQuery } from "@apollo/client";

export const useCustomerReport = (): IuseReturn => {
  const useCustomer = gql`
  query GetCustomers($whereString: CustomerFilterInput!) {
    customers(where: $whereString) {
     id
     firstName
     lastName 
     balance
    }
  }
`;
const { loading, error, data, refetch } = useQuery(useCustomer, {
skip: true, // Skip the initial execution
});

return { refetch, data, loading, error };
}

