import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

export const GridFields = (): Array<GridColDef> => {
  const { t } = useTranslation();
  return [
    {
      field: "id",
      headerName: t("order") || "",
      width: 100,
    },
    {
      field: "vendorId",
      headerName: t("vendor") || "",
      width: 200,
    },
    {
      field: "account1",
      headerName: t("account1") || "",
      width: 200,
    },
    {
      field: "account2",
      headerName: t("account2") || "",
      width: 200,
    },
    {
      field: "amount",
      headerName: t("amount") || "",
      width: 200,
    },
    {
      field: "docDate",
      headerName: t("docdate") || "",
      width: 200,
    },
    {
      field: "customerId",
      headerName: t("customer") || "",
      width: 200,
    },
    {
      field: "statusId",
      headerName: t("status") || "",
      width: 200,
    },
    {
      field: "completedDate",
      headerName: t("completedate") || "",
      width: 200,
    },
    {
      field: "completedBy",
      headerName: t("completeby") || "",
      width: 200,
    },
  ];
};
